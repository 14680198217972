import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Table,
    Form,
    ListGroup,
    Tabs,
    Modal,
    Tab,
    Badge,
    InputGroup,
    Container,
    Button,
    ButtonGroup,
    Nav,
    Offcanvas,
    Card,
    Spinner,
    Dropdown,
} from "react-bootstrap";
import { FaArrowDown, FaArrowUp, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import {
    listApplications,
    listApplicationsRecordsPaginated,
    detailApplication,
    commentsApplication,
    addCommentAction,
    logsApplication,
    updateApplication,
} from "../../actions/applicationActions";
import { retrieveFileAction } from "../../actions/fileActions";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridOptions } from "ag-grid-community";

import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import ModalImage from "../ModalImage";

import TimeAgo from "javascript-time-ago";
import StringCrypto from "string-crypto";

// English.
import en from "javascript-time-ago/locale/en.json";

import "./ApplicationForm.css";

import { uploadFileAction } from "../../actions/fileActions";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import ViewAppRecords from "./ViewAppRecords";
const MySwal = withReactContent(Swal);
let p = {};

TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

function RecordsForm2() {

    const Toast = MySwal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });

    const [show, setShow] = useState(false);

    let total_rebate = 0;
    const [totalRebate, setTotalRebate] = useState(0);
    const [applicationId, setApplicationId] = useState(0);

    const dispatch = useDispatch();

    const applicationListRecord = useSelector(
        (state) => state.applicationListRecord
    );
    const { applications } = applicationListRecord;
    
    const applicationDetail = useSelector((state) => state.applicationDetail);
    const { loading, error, application } = applicationDetail;

    const [reload, setReload] = useState(0);

    // Pagination
    const [currentPage, setPage] = useState(1);
    const [currentPageCount, setPageCount] = useState(null);
    const handlePage = (newPage) => {
        const tempCount = localStorage.getItem("RecordsCount");
        const tempCount2 = typeof parseInt(tempCount) === typeof 0 ? tempCount : 0;
        if (newPage > 0 && tempCount2 >= newPage) {
            setPage(newPage);
        }
    };

    const onButtonClick = (selected) => {
        Toast.fire({
            icon: "info",
            title: "Loading Data",
            text: "Please wait while the table is loading the data.",
        });
        dispatch(detailApplication(selected.Application_Id));
        setApplicationId(selected.Application_Id);

        dispatch(commentsApplication(selected.Application_Id));
        dispatch(logsApplication(selected.Application_Id));
        setShow(true);
    };

    // Column Headers Components

    const [controlNumAsc, setControlNumAsc] = useState(null);
    const [controlNumType, setControlNumType] = useState("");
    const [controlNumSelect1, setControlNumSelect1] = useState("contains");
    const [controlNumSelect2, setControlNumSelect2] = useState("contains");
    const [controlNumKeyword1, setControlNumKeyword1] = useState("");
    const [controlNumKeyword2, setControlNumKeyword2] = useState("");

    const [bacthCodeAsc, setBatchCodeAsc] = useState(null);
    const [bacthCodeType, setBatchCodeType] = useState("");
    const [bacthCodeSelect1, setBatchCodeSelect1] = useState("contains");
    const [bacthCodeSelect2, setBatchCodeSelect2] = useState("contains");
    const [bacthCodeKeyword1, setBatchCodeKeyword1] = useState("");
    const [bacthCodeKeyword2, setBatchCodeKeyword2] = useState("");

    const [nameAsc, setNameAsc] = useState(null);
    const [nameType, setNameType] = useState("");
    const [nameSelect1, setNameSelect1] = useState("contains");
    const [nameSelect2, setNameSelect2] = useState("contains");
    const [nameKeyword1, setNameKeyword1] = useState("");
    const [nameKeyword2, setNameKeyword2] = useState("");

    const [appDateAsc, setAppDateAsc] = useState(null);
    const [appDateType, setAppDateType] = useState("");
    const [appDateSelect1, setAppDateSelect1] = useState("contains");
    const [appDateSelect2, setAppDateSelect2] = useState("contains");
    const [appDateKeyword1, setAppDateKeyword1] = useState("");
    const [appDateKeyword2, setAppDateKeyword2] = useState("");

    const [stageAsc, setStageAsc] = useState(null);
    const [stageType, setStageType] = useState("");
    const [stageSelect1, setStageSelect1] = useState("contains");
    const [stageSelect2, setStageSelect2] = useState("contains");
    const [stageKeyword1, setStageKeyword1] = useState("");
    const [stageKeyword2, setStageKeyword2] = useState("");

    const [statusAsc, setStatusAsc] = useState(null);
    const [statusType, setStatusType] = useState("");
    const [statusSelect1, setStatusSelect1] = useState("contains");
    const [statusSelect2, setStatusSelect2] = useState("contains");
    const [statusKeyword1, setStatusKeyword1] = useState("");
    const [statusKeyword2, setStatusKeyword2] = useState("");

    const [systemTypeAsc, setSystemTypeAsc] = useState(null);
    const [systemTypeType, setSystemTypeType] = useState("");
    const [systemTypeSelect1, setSystemTypeSelect1] = useState("contains");
    const [systemTypeSelect2, setSystemTypeSelect2] = useState("contains");
    const [systemTypeKeyword1, setSystemTypeKeyword1] = useState("");
    const [systemTypeKeyword2, setSystemTypeKeyword2] = useState("");

    const applyFilter = () => {
        Toast.fire({
            icon: "info",
            title: "Filtering entries",
            text: "Please wait while the table is loading the data.",
        });
        setPage(1)
        dispatch(listApplicationsRecordsPaginated(
            "1",
            controlNumAsc,
            controlNumType,
            controlNumSelect1,
            controlNumSelect2,
            controlNumKeyword1,
            controlNumKeyword2,
            bacthCodeAsc,
            bacthCodeType,
            bacthCodeSelect1,
            bacthCodeSelect2,
            bacthCodeKeyword1,
            bacthCodeKeyword2,
            nameAsc,
            nameType,
            nameSelect1,
            nameSelect2,
            nameKeyword1,
            nameKeyword2,
            appDateAsc,
            appDateType,
            appDateSelect1,
            appDateSelect2,
            appDateKeyword1,
            appDateKeyword2,
            stageAsc,
            stageType,
            stageSelect1,
            stageSelect2,
            stageKeyword1,
            stageKeyword2,
            statusAsc,
            statusType,
            statusSelect1,
            statusSelect2,
            statusKeyword1,
            statusKeyword2,
            systemTypeAsc,
            systemTypeType,
            systemTypeSelect1,
            systemTypeSelect2,
            systemTypeKeyword1,
            systemTypeKeyword2,
        ));
        dispatch(detailApplication(applicationId));
        dispatch(logsApplication(applicationId));

        dispatch(commentsApplication(applicationId));
    };

    const applySort = () => {
        Toast.fire({
            icon: "info",
            title: "Sorting entries",
            text: "Please wait while the table is loading the data.",
        });
        setPage(1);
        dispatch(listApplicationsRecordsPaginated(
            "1",
            controlNumAsc,
            controlNumType,
            controlNumSelect1,
            controlNumSelect2,
            controlNumKeyword1,
            controlNumKeyword2,
            bacthCodeAsc,
            bacthCodeType,
            bacthCodeSelect1,
            bacthCodeSelect2,
            bacthCodeKeyword1,
            bacthCodeKeyword2,
            nameAsc,
            nameType,
            nameSelect1,
            nameSelect2,
            nameKeyword1,
            nameKeyword2,
            appDateAsc,
            appDateType,
            appDateSelect1,
            appDateSelect2,
            appDateKeyword1,
            appDateKeyword2,
            stageAsc,
            stageType,
            stageSelect1,
            stageSelect2,
            stageKeyword1,
            stageKeyword2,
            statusAsc,
            statusType,
            statusSelect1,
            statusSelect2,
            statusKeyword1,
            statusKeyword2,
            systemTypeAsc,
            systemTypeType,
            systemTypeSelect1,
            systemTypeSelect2,
            systemTypeKeyword1,
            systemTypeKeyword2,
        ));
        dispatch(detailApplication(applicationId));
        dispatch(logsApplication(applicationId));

        dispatch(commentsApplication(applicationId));
    }

    const resetState = () => {
        setControlNumAsc(null);
        setControlNumType("");
        setControlNumSelect1("contains");
        setControlNumSelect2("contains");
        setControlNumKeyword1("");
        setControlNumKeyword2("");

        setBatchCodeAsc(null);
        setBatchCodeType("");
        setBatchCodeSelect1("contains");
        setBatchCodeSelect2("contains");
        setBatchCodeKeyword1("");
        setBatchCodeKeyword2("");

        setNameAsc(null);
        setNameType("");
        setNameSelect1("contains");
        setNameSelect2("contains");
        setNameKeyword1("");
        setNameKeyword2("");

        setAppDateAsc(null);
        setAppDateType("");
        setAppDateSelect1("contains");
        setAppDateSelect2("contains");
        setAppDateKeyword1("");
        setAppDateKeyword2("");

        setStageAsc(null);
        setStageType("");
        setStageSelect1("contains");
        setStageSelect2("contains");
        setStageKeyword1("");
        setStageKeyword2("");

        setStatusAsc(null);
        setStatusType("");
        setStatusSelect1("contains");
        setStatusSelect2("contains");
        setStatusKeyword1("");
        setStatusKeyword2("");

        setSystemTypeAsc(null);
        setSystemTypeType("");
        setSystemTypeSelect1("contains");
        setSystemTypeSelect2("contains");
        setSystemTypeKeyword1("");
        setSystemTypeKeyword2("");

        reloadTableHandler();
    };

    useEffect(() => {
        Toast.fire({
            icon: "info",
            title: "Loading Data",
            text: "Please wait while the table is loading the data.",
        });
        dispatch(listApplicationsRecordsPaginated(
            currentPage.toString(),
            controlNumAsc,
            controlNumType,
            controlNumSelect1,
            controlNumSelect2,
            controlNumKeyword1,
            controlNumKeyword2,
            bacthCodeAsc,
            bacthCodeType,
            bacthCodeSelect1,
            bacthCodeSelect2,
            bacthCodeKeyword1,
            bacthCodeKeyword2,
            nameAsc,
            nameType,
            nameSelect1,
            nameSelect2,
            nameKeyword1,
            nameKeyword2,
            appDateAsc,
            appDateType,
            appDateSelect1,
            appDateSelect2,
            appDateKeyword1,
            appDateKeyword2,
            stageAsc,
            stageType,
            stageSelect1,
            stageSelect2,
            stageKeyword1,
            stageKeyword2,
            statusAsc,
            statusType,
            statusSelect1,
            statusSelect2,
            statusKeyword1,
            statusKeyword2,
            systemTypeAsc,
            systemTypeType,
            systemTypeSelect1,
            systemTypeSelect2,
            systemTypeKeyword1,
            systemTypeKeyword2,
        ));
        dispatch(detailApplication(applicationId));
        dispatch(logsApplication(applicationId));

        dispatch(commentsApplication(applicationId));
    }, [reload, currentPage]);

    const reloadTableHandler = () => {
        setReload(reload + 1)
    }

    total_rebate = 0;

    useEffect(() => {
        if (application?.Info_Mailing_address != undefined) {
            const words = application.Info_Mailing_address.split("^")
            // setMailingAddressFix(words[0])
            // setCountryFix(words[1] ? words[1] : "N/A")
        }
        if (application?.New_equipment != undefined) {
            application.New_equipment.map((eq) => {
                total_rebate = total_rebate + eq.newEquip_rebate
                console.log(eq.newEquip_rebate)
            });
            setTotalRebate(total_rebate)
            console.log("application: ", application);
            console.log("totalRebate: ", total_rebate);
        }

    }, [application]);

    useEffect(() => {
        const tempCount = localStorage.getItem("RecordsCount");
        if(tempCount && !show){
            setPageCount(tempCount);
        }
    });

    return (
        <div>
            {!show ? (
                <Container>
                    <div
                        className="ag-theme-alpine"
                        style={{ height: 400, width: 100 + "%" }}
                    >
                        {/* <Button onClick={() => printState()} className="me-2" variant={"info"}>Print State</Button> */}
                        {/* <Button onClick={() => saveState()} className="me-2" size='sm' variant={"success"}>Save State</Button>
                      <Button onClick={() => restoreState()} className="me-2" size='sm' variant={"secondary"}>Restore State</Button> */}
                        <Row>
                            <Col md="12" style={{ padding: 0 }}>
                                <Button
                                    onClick={() => resetState()}
                                    className="mb-2 float-end"
                                    size="sm"
                                    variant={"success"}
                                >
                                    Reset Filter
                                </Button>
                                <Button
                                    onClick={() => reloadTableHandler()}
                                    className="mb-2 me-2 float-end"
                                    size="sm"
                                    variant={"success"}
                                >
                                    Reload Table
                                </Button>
                            </Col>
                        </Row>


                        {/* NOTE: Custom long        */}
                        {/* code ahead               */}

                        {/* Custom Data Table Header */}
                        <div className="d-flex flex-row justify-content-between align-items-center bg-info py-2 ps-2 pe-4">

                            {/* Column 1 */}
                            {/* Control Number */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Control Number">Control Number</p>
                                {
                                    controlNumAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(false);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(true);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setControlNumSelect1(e.target.value)} value={controlNumSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setControlNumKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={controlNumKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={controlNumType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="controlNum"
                                                    onChange={(e) => setControlNumType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={controlNumType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="controlNum"
                                                    onChange={(e) => setControlNumType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setControlNumSelect2(e.target.value)} value={controlNumSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setControlNumKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={controlNumKeyword2}
                                            />
                                            <DropdownItem className="m-0 p-0">
                                                <Button
                                                    className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                    variant="info"
                                                    onClick={() => applyFilter()}
                                                    title="APPLY"
                                                >
                                                    <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                                </Button>
                                            </DropdownItem>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 2 */}
                            {/* Batch Code */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Batch Code">Batch Code</p>
                                {
                                    bacthCodeAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(false);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(true);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setBatchCodeSelect1(e.target.value)} value={bacthCodeSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setBatchCodeKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={bacthCodeKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={bacthCodeType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="batchCode"
                                                    onChange={(e) => setBatchCodeType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={bacthCodeType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="batchCode"
                                                    onChange={(e) => setBatchCodeType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setBatchCodeSelect2(e.target.value)} value={bacthCodeSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setBatchCodeKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={bacthCodeKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 3 */}
                            {/* Name */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Name">Name</p>
                                {
                                    nameAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(false);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(true);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setNameSelect1(e.target.value)} value={nameSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setNameKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={nameKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={nameType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="nameType"
                                                    onChange={(e) => setNameType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={nameType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="nameType"
                                                    onChange={(e) => setNameType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setNameSelect2(e.target.value)} value={nameSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setNameKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={nameKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 4 */}
                            {/* Application Date */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Application Date">Application Date</p>
                                {
                                    appDateAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(false);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(true);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setAppDateSelect1(e.target.value)} value={appDateSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setAppDateKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={appDateKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={appDateType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="appDate"
                                                    onChange={(e) => setAppDateType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={appDateType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="appDate"
                                                    onChange={(e) => setAppDateType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setAppDateSelect2(e.target.value)} value={appDateSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setAppDateKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={appDateKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 5 */}
                            {/* Stage */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Stage">Stage</p>
                                {
                                    stageAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(false);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(true);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setStageSelect1(e.target.value)} value={stageSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setStageKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={stageKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={stageType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="stage"
                                                    onChange={(e) => setStageType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={stageType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="stage"
                                                    onChange={(e) => setStageType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setStageSelect2(e.target.value)} value={stageSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setStageKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={stageKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 6 */}
                            {/* Status */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="Status">Status</p>
                                {
                                    statusAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(false);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(true);
                                                setSystemTypeAsc(null);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setStatusSelect1(e.target.value)} value={statusSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setStatusKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={statusKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={statusType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="status"
                                                    onChange={(e) => setStatusType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={statusType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="status"
                                                    onChange={(e) => setStatusType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setStatusSelect2(e.target.value)} value={statusSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setStatusKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={statusKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 7 */}
                            {/* System Type */}
                            <div className="highlight-item my-custom-button w-125 d-flex flex-row justify-content-center align-items-center">
                                <p className="text-white text-center text-truncate fw-bold fs-16 m-0 p-0" title="System Type">System Type</p>
                                {
                                    systemTypeAsc
                                        ? <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(false);
                                                applySort();
                                            }}
                                            title="Ascending"
                                        >
                                            <FaArrowUp className="m-1 fs-12" />
                                        </Button>
                                        : <Button
                                            className="highlight-item-child flex-row justify-content-center align-items-center p-0 mx-1 my-0"
                                            variant="info"
                                            onClick={() => {
                                                setControlNumAsc(null);
                                                setBatchCodeAsc(null);
                                                setNameAsc(null);
                                                setAppDateAsc(null);
                                                setStageAsc(null);
                                                setStatusAsc(null);
                                                setSystemTypeAsc(true);
                                                applySort();
                                            }}
                                            title="Descending"
                                        >
                                            <FaArrowDown className="m-1 fs-12" />
                                        </Button>
                                }
                                {/* Custom Filter Options UI */}
                                <Dropdown className="highlight-item-child">
                                    <Dropdown.Toggle className="mx-1 my-0 p-0" variant="info" id="dropdown-basic" title="Filter Menu">
                                        <IoMenu className="m-1 fs-12" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <div className="w-200px p-2">
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setSystemTypeSelect1(e.target.value)} value={systemTypeSelect1}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setSystemTypeKeyword1(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={systemTypeKeyword1}
                                            />
                                            <div className="my-custom-radio d-flex flex-row justify-content-around align-items-center pt-2">
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={systemTypeType == "AND"}
                                                    type="radio"
                                                    label="AND"
                                                    name="systemtype"
                                                    onChange={(e) => setSystemTypeType("AND")}
                                                />
                                                <Form.Check
                                                    className="fs-12"
                                                    checked={systemTypeType == "OR"}
                                                    type="radio"
                                                    label="OR"
                                                    name="systemtype"
                                                    onChange={(e) => setSystemTypeType("OR")}
                                                />
                                            </div>
                                            <Form.Select className="p-1 fs-12 my-form-select" onChange={(e) => setSystemTypeSelect2(e.target.value)} value={systemTypeSelect2}>
                                                {/* <option value="" >Choose</option> */}
                                                <option value="contains" >Contains</option>
                                                <option value="notContains">Not contains</option>
                                                <option value="equal">Equals</option>
                                                <option value="notEqual">Not equal</option>
                                                <option value="startsWith">Starts with</option>
                                                <option value="endsWith">Ends with</option>
                                            </Form.Select>
                                            <Form.Control
                                                className="my-form-control rounded p-1 my-1"
                                                id="filter1"
                                                onChange={(e) => setSystemTypeKeyword2(e.target.value)}
                                                placeholder="Filter..."
                                                style={{ fontSize: '12px' }}
                                                type="text"
                                                value={systemTypeKeyword2}
                                            />
                                            <Button
                                                className="d-flex flex-row justify-content-center align-items-center w-100 p-0 mx-0 my-2 fs-12"
                                                variant="info"
                                                onClick={() => applyFilter()}
                                                title="APPLY"
                                            >
                                                <p className="text-white text-center fw-bold fs-16 m-0 p-0">APPLY</p>
                                            </Button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            {/* Column 8 */}
                            {/* Actions */}
                            <div className="w-125">
                                <Button
                                    className="d-flex flex-row justify-content-center align-items-center w-100 p-0 m-0"
                                    variant="info"
                                    onClick={() => { }}
                                    title="Actions"
                                >
                                    <p className="text-white text-center fw-bold fs-16 m-0 p-0">Actions</p>
                                </Button>
                            </div>
                        </div>
                        {/* End of Custom Data Table Header */}

                        {/* Custom Data Table Rows */}
                        <div className="border border-secondary h-350px overflow-y-scroll">
                            {
                                applications
                                    ? applications.map((data) => (
                                        <div key={data.Control_Number} className="d-flex flex-row justify-content-between align-items-center border-bottom border-secondary p-2">
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.Control_Number}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.Batch_code}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.customer_name}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.Application_Date}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.Stage}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.Status}</p>
                                            </div>
                                            <div className="w-125">
                                                <p className="text-dark text-center fs-14 m-0 p-0">{data.System_Type}</p>
                                            </div>
                                            <div className="w-125">
                                                <div
                                                    className="d-flex flex-row justify-content-center align-items-center p-0 m-0"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Button
                                                        style={{ width: 70 }}
                                                        variant="success"
                                                        size="sm"
                                                        onClick={() => onButtonClick(data)}
                                                    >
                                                        View
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    : <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                                        <p className="text-secondary text-center fs-16">Loading data ...</p>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="mt-5 d-flex flex-row justify-content-center align-items-center">
                        <Button
                            className="mx-2"
                            onClick={() => handlePage(currentPage - 1)}
                            size="sm"
                            variant={"success"}
                        >
                            <FaChevronLeft className="" />
                        </Button>
                        <Button
                            className={`mx-1 fw-bold text-success ${currentPage - 2 <= 0 ? "d-none" : ""}`}
                            onClick={() => handlePage(currentPage - 2)}
                            size="sm"
                            variant={"light"}
                        >
                            {currentPage - 2}
                        </Button>
                        <Button
                            className={`mx-1 fw-bold text-success ${currentPage - 1 <= 0 ? "d-none" : ""}`}
                            onClick={() => handlePage(currentPage - 1)}
                            size="sm"
                            variant={"light"}
                        >
                            {currentPage - 1}
                        </Button>
                        <Button
                            className="mx-1 fw-bold"
                            onClick={() => { }}
                            size="sm"
                            variant={"success"}
                        >
                            {currentPage}
                        </Button>
                        {
                            currentPageCount !== null || currentPageCount !== "null" || currentPageCount !== "undefined"
                            ?   currentPageCount >= (currentPage + 1)
                                ?   <Button
                                        className="mx-1 fw-bold text-success"
                                        onClick={() => handlePage(currentPage + 1)}
                                        size="sm"
                                        variant={"light"}
                                    >
                                        {currentPage + 1}
                                    </Button>
                                :   <></>
                            :   <Button
                                    className="mx-1 fw-bold text-success"
                                    onClick={() => handlePage(currentPage + 1)}
                                    size="sm"
                                    variant={"light"}
                                >
                                    {currentPage + 1}
                                </Button>
                        }
                        {
                            currentPageCount !== null || currentPageCount !== "null" || currentPageCount !== "undefined"
                            ?   currentPageCount >= (currentPage + 2)
                                ?   <Button
                                        className="mx-1 fw-bold text-success"
                                        onClick={() => handlePage(currentPage + 2)}
                                        size="sm"
                                        variant={"light"}
                                    >
                                        {currentPage + 2}
                                    </Button>
                                :   <></>
                            :   <Button
                                    className="mx-1 fw-bold text-success"
                                    onClick={() => handlePage(currentPage + 2)}
                                    size="sm"
                                    variant={"light"}
                                >
                                    {currentPage + 2}
                                </Button>
                        }
                        <Button
                            className="mx-2"
                            onClick={() => handlePage(currentPage + 1)}
                            size="sm"
                            variant={"success"}
                        >
                            <FaChevronRight className="" />
                        </Button>
                    </div>
                </Container>
            ) : (
                <ViewAppRecords
                    show={show}
                    setShow={setShow}
                    reload={reload}
                    setReload={setReload}
                    applicationId={applicationId}
                    setApplicationId={setApplicationId}
                    isApplication={false}
                />
            )}
        </div>
    );
}

export default RecordsForm2;
