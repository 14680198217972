export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_FAIL = "FILE_UPLOAD_FAIL";

export const FILE_RETRIEVE_REQUEST = "FILE_RETRIEVE_REQUEST";
export const FILE_RETRIEVE_SUCCESS = "FILE_RETRIEVE_SUCCESS";
export const FILE_RETRIEVE_FAIL = "FILE_RETRIEVE_FAIL";

export const PDF_RETRIEVE_REQUEST = "PDF_RETRIEVE_REQUEST";
export const PDF_RETRIEVE_SUCCESS = "PDF_RETRIEVE_SUCCESS";
export const PDF_RETRIEVE_FAIL = "PDF_RETRIEVE_FAIL";

export const LOG_FILE_REQUEST = "LOG_FILE_REQUEST";
export const LOG_FILE_SUCCESS = "LOG_FILE_SUCCESS";
export const LOG_FILE_FAIL = "LOG_FILE_FAIL";


export const UPDATE_FILE_REQUEST = "UPDATE_FILE_REQUEST";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";
export const UPDATE_FILE_FAIL = "UPDATE_FILE_FAIL";
