import React, {useEffect, useState, CSSProperties} from "react";
import { Row, Col, Image } from "react-bootstrap";

import Loader from "react-spinners/PulseLoader";

import { Link } from "react-router-dom";

import "./HomeScreen.css";

const loadingStyle = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

function iOS() {

  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}


function  LoadingScreen() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#8BC440");
  const [isIOS, setIsIOS] = useState(false);
  
  useEffect(()=>{
    if (iOS()) {
      setIsIOS(true);
    }
  }, []);

  return (
    <Row style={{justifyContent:"center"}}>
        <div className="text-center position-relative main-content">
            <Loader color={color} loading={loading} size={18} cssOverride={loadingStyle} />
        </div>
    </Row>
  );
}

export default LoadingScreen;
