export const EQUIPMENT_ADD_REQUEST = "EQUIPMENT_ADD_REQUEST";
export const EQUIPMENT_ADD_SUCCESS = "EQUIPMENT_ADD_SUCCESS";
export const EQUIPMENT_ADD_FAIL = "EQUIPMENT_ADD_FAIL";

export const EQUIPMENT_LIST_REQUEST = "EQUIPMENT_LIST_REQUEST";
export const EQUIPMENT_LIST_SUCCESS = "EQUIPMENT_LIST_SUCCESS";
export const EQUIPMENT_LIST_FAIL = "EQUIPMENT_LIST_FAIL";
export const EQUIPMENT_LIST_RESET = "EQUIPMENT_LIST_RESET";

export const EQUIPMENT_DELETE_REQUEST = "EQUIPMENT_DELETE_REQUEST";
export const EQUIPMENT_DELETE_SUCCESS = "EQUIPMENT_DELETE_SUCCESS";
export const EQUIPMENT_DELETE_FAIL = "EQUIPMENT_DELETE_FAIL";

export const EQUIPMENT_UPDATE_REQUEST = "EQUIPMENT_UPDATE_REQUEST";
export const EQUIPMENT_UPDATE_SUCCESS = "EQUIPMENT_UPDATE_SUCCESS";
export const EQUIPMENT_UPDATE_FAIL = "EQUIPMENT_UPDATE_FAIL";
