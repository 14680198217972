import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import ReactPDF, {
  PDFDownloadLink,
  PDFViewer,
  View,
  Document,
  Text,
  Page,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import black from "../components/fonts/Inter-Black.ttf";
import bold from "../components/fonts/Inter-Bold.ttf";
import regular from "../components/fonts/Inter-Regular.ttf";
import CustomerHeader from "../components/CustomerHeader";
import StringCrypto from "string-crypto";
import { Link, useLocation } from "react-router-dom";
import { printDetailApplication } from "../actions/applicationActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import city_zipcode from "./city_zipcode";

import { BrowserView, MobileView } from 'react-device-detect';
import "./PrintApplicationSummary.css";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const MySwal = withReactContent(Swal);


// Font.register({
//   family: "Inter",
//   fonts: [
//     {
//       src: "Montserrat", // black,
//       fontWeight: 700,
//     },
//     {
//       src: "Montserrat", // bold,
//       fontWeight: 500,
//     },
//     {
//       src: "Montserrat", // regular,
//       fontWeight: 300,
//     },
//   ],
// })

const styles = StyleSheet.create({
  title: {
    fontWeight: 500,
    fontSize: 13.5,
    color: "#233E86",
     
  },
  title1: {
    fontWeight: 500,
    fontSize: 10,
    color: "#233E86",
     
  },
  title3: {
    fontWeight: 400,
    fontSize: 14,
    color: "#8BC440"
  },
  text: {
    fontSize: 10,
    color: "#333333",
    fontWeight: 400,
    fontStyle: "normal",
     
    wordWrap: "inherit"
  },
  textBold: {
    fontSize: 10,
    color: "#333333",
    fontWeight: 500,
     
  },
  textLine: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 2
  },
  whiteSpace: {
    marginTop: 15
  },
  mainTable: {
    flexDirection: "row",
    alignSelf: "center",
    maxWidth: "100%"
  },
  columns: {
    display: "flex",
    width: 150,
    textAlign: "center",
    justifyContent: "center"
  },
  tableCellEmpty:{
    backgroundColor: "#FFFFFF",
    padding: 2,
    margin: 0.5,
    color: "white",
     
    fontSize: 12,
    fontWeight: 300,
    height: 40
  },
  tableCellTitle: {
    justifyContent: "center",
    backgroundColor: "#223F89",
    padding: 2,
    margin: 0.5,
    textAlign: "center",
    color: "white",
     
    fontSize: 10,
    fontWeight: 500,
    height: 40,
    wordWrap: "inherit",
    width: "100%"
  },
  tableCell: {
    backgroundColor: "#FFFFFF",
    border: 0.5,
    margin: 0.5,
    textAlign: "center",
     
    fontSize: 10,
    fontWeight: 300,
    height: 40,
    wordWrap: "normal",
    width: "100%",
    justifyContent: "center"
  },
  boxContainer: { 
    flex: 1, paddingHorizontal: 20, paddingVertical: 10 
  },
  banner: {
    flexDirection: "row",
    height: 60
  },
  titleBoxContainer: {
    flex: 1,
    marginTop: 10,
    textAlign: "center"
  },
  bannerSides: {
    marginHorizontal: 20,
    height: "auto",
    alignContent: "center"
  }
});

const NewEquipmentTable = ({ data, finalDate, index, Delay_Reason, Delay_Reason2, current }) => {
  return (
    <>

      <View style={styles.columns}>
        <View style={{
          backgroundColor: "#223F89",
          padding: 3,
          margin: 0.5,
          color: "white",
           
          fontSize: 10,
          fontWeight: 500,
          height: 40,
          justifyContent: "center"
        }}>
          <Text>Equipment {index + 1 + current}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_System_type || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_Vendor || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_Manufacturer || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_Model_no || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text style={styles.text}>{data?.newEquip_Quantity || "N/A"}</Text>
        </View>
        {data?.newEquip_System_type == "Washer" || data?.newEquip_System_type == "Dryer" ?
          <View style={styles.tableCell}>
            <Text 
            style={{
              fontSize: 9,
              color: "#333333",
              fontWeight: 400,
              fontStyle: "normal",
               
              wordWrap: "inherit"
            }}
            >{data?.newEquip_Seer || "N/A"}</Text>
          </View>
         :
         <>
         <View style={styles.tableCell}>
            <Text 
            style={{
              fontSize: 9,
              color: "#333333",
              fontWeight: 400,
              fontStyle: "normal",
               
              wordWrap: "inherit"
            }}
            >{data?.newEquip_Btu || "N/A"}</Text>
          </View>
          <View style={styles.tableCell}>
            <Text 
            style={{
              fontSize: 9,
              color: "#333333",
              fontWeight: 400,
              fontStyle: "normal",
               
              wordWrap: "inherit"
            }}
            >{data.newEquip_Btu != null ? parseInt(parseFloat(data.newEquip_Btu) * 12000) : "N/A"}</Text>
          </View>
        </>
        }
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_Invoice_no || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_Purchase_date || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{finalDate || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{Delay_Reason2 || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{Delay_Reason || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.newEquip_rebate || "0.00"}</Text>
        </View>
      </View>
    </>
  );
};

const OldEquipmentTable = ({ data, finalDate, index, current }) => {
  return (
    <>
      <View style={styles.columns}>
        <View style={{
          backgroundColor: "#223F89",
          padding: 3,
          margin: 0.5,
          color: "white",
           
          fontSize: 10,
          fontWeight: 500,
          height: 40,
          justifyContent: "center"
        }}>
          <Text>Equipment {index + 1 + current}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_System_type || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Tons || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Btu || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Quantity || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Years || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Conditon || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Seer || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Disposal_party || "N/A"}</Text>
        </View>
        <View style={styles.tableCell}>
          <Text 
          style={{
            fontSize: 9,
            color: "#333333",
            fontWeight: 400,
            fontStyle: "normal",
             
            wordWrap: "inherit"
          }}
          >{data?.oldEquip_Disposal_date || "N/A"}</Text>
        </View>
      </View>
    </>
  );
};

const Line = () => {
  return (
    <View
      style={{
        width: "100%",
        paddingHorizontal: 20,
      }}
    >
      <View
        style={{
          height: 3,
          width: "100%",
          backgroundColor: "#C4C4C480",
        }}
      ></View>
    </View>
  );
};

const StatusIcon = ({ check }) => {
  return (
    <>
    
      {check ? (
        <Image
          src={require("../components/icons/yes.png")}
          style={{ width: 12, height: 12, marginLeft: "auto", marginTop: 2 }}
          fixed={true}
        />
      ) : (
        <Image
          src={require("../components/icons/no.png")}
          style={{ width: 12, height: 12, marginLeft: "auto", marginTop: 2 }}
          fixed={true}
        />
      )}
    </>
  );
};

const template = {
  Application_Id: 0,
  Control_Number: "",
  Status: "",
  Stage: "",
  Delay_Reason: "",
  Delay_Reason2: "",
  Type: "",
  Application_Date: "",
  Last_Modified_On: "",
  Info_Account_no: "",
  Info_Bill_id: "",
  Info_Customer_name: "",
  Info_Service_location: "",
  Info_City_village: "",
  Info_Zipcode: "",
  Info_Email: "",
  Info_Tel_no: "",
  Info_Is_owner: "",
  Info_Mailing_address: "",
  Info_Mailing_city: "",
  Info_Mailing_zip: "",
  Info_Home_size: "",
  Info_Home_age: "",
  Info_Home_type: "",
  Info_New_construction: "",
  Old_equipment: [],
  Installer_New_name: "",
  Installer_New_worktel: "",
  Installer_New_companyname: "",
  Installer_New_certno: null,
  Installer_New_finaldate: "",
  Installer_New_email: null,
  New_equipment: [
    {
      newEquip_System_type: "",
      newEquip_Vendor: "",
      newEquip_Quantity: 0,
      newEquip_Btu: "",
      newEquip_Size: null,
      newEquip_Manufacturer: "",
      newEquip_Model_no: "",
      newEquip_Invoice_no: "",
      newEquip_Purchase_date: "",
      newEquip_Type: null,
      newEquip_Seer:"",
      newEquip_rebate: null,
      newEquip_Tons: null,
    },
  ],
  Submitted_docs: [
    {
      invoice: null,
      irs_form: null,
      disposal_slip: null,
      letter_authorization: null,
      installer_cert: null,
      other_doc2: null,
      other_doc3: null,
    },
  ],
};

function PrintApplicationSummary2(props) {

  const Toast = MySwal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { decryptString } = new StringCrypto();
  const applicationPrintDetail = useSelector(
    (state) => state.applicationPrintDetail
  );
  let query = useQuery();
  let creds = query.get("auth");
  const [data, setData] = useState(template);
  const [accountType, setAccountType] = useState();
  const [mailingAddressFix, setMailingAddressFix] = useState("");
  const [countryFix, setCountryFix] = useState("");
  const [newEquipmentPageCount, setNewEquipmentPageCount] = useState(0);
  const [newEquipmentCurrent, setNewEquipmentCurrent] = useState(0);
  const [oldEquipmentPageCount, setOldEquipmentPageCount] = useState(0);
  const [oldEquipmentCurrent, setOldEquipmentCurrent] = useState(0);
  

  let totalRebate = 0;
  let totalRebate2 = 0;

  const retriveTermsAndCondition = useSelector(
    (state) => state.retriveTermsAndCondition
  );

  const pdfWrapperRef = React.useRef();

  useEffect(() => {

     Toast.fire({
       icon: "info",
       title: "Loading Document",
       text: "Please wait while file is being fetched.",
     });

    if (creds) {
      dispatch(
        printDetailApplication(decryptString(creds, "superSecureToken"))
      );
    } else {
      Swal.fire("ERROR", "Application doesn't exist.", "error").then(() => {
        props.history.push("/");
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (applicationPrintDetail?.application.length !== 0) {
      setData(applicationPrintDetail.application);
    }
  }, [applicationPrintDetail]);

    function overlapFix(text) 
    {
      if(text != null){
        const str = text.trim()
        let line = Math.round(text.length / 18);
        console.log("TEXT: ", str);
        console.log("LINE: ", line);
        let arr1 = [];
        if (line > 0) {
          for (let i = 0; i < line; i++) {

            console.log("TEXT STRING: ", str.substring(i * 18, (i + 1) * 18));
            arr1.push(str.substring(i * 18, (i + 1) * 18));
          }
            if(line == 1){
              console.log("LINE IS EQUAL TO 1");
              arr1 =  [];
              arr1.push(str.substring(line-1 * 18));
            }
        }
        else{
          arr1.push(str);
        }
        return arr1;
      }
      
    }

  let arr1 = [];
  let arr2 = [];
  let arr3 = [];
  let arr4 = [];

  if (data.Delay_Reason || data.Delay_Reason2)
  {
      arr1 = overlapFix(data.Delay_Reason);
      arr2 = overlapFix(data.Delay_Reason2);

      console.log("ARR1: ", arr1);
      console.log("ARR2", arr2);
  }

  useEffect(() => {
    console.log("test")
    const length = data.New_equipment.length;
    if(length > 0){
      const pages = Math.ceil(length / 3)
      console.log(pages)
      setNewEquipmentPageCount(pages)
    }
  }, [data.New_equipment]);

  useEffect(() => {
    console.log("test")
    const length = data.Old_equipment.length;
    if(length > 0){
      const pages = Math.ceil(length / 3)
      console.log(pages)
      setOldEquipmentPageCount(pages)
    }
  }, [data.Old_equipment]);

  // Info_Service_location;
  arr3 = overlapFix(data.Info_Service_location);
  arr4 = overlapFix(data.Info_Mailing_address);
  console.log("ARR3", arr3);
  console.log("ARR4", arr4);

  console.log(data)
  console.log("ARR1: ", arr1);
  console.log("ARR2", arr2);
  console.log("newEquipmentPages", newEquipmentPageCount)

  const PrintApp = () => (
    <Document className="document-pdf">

      <Page size="LETTER">
        <View
          style={{
            marginTop: "5.88%",
            marginLeft: "5.88%",
            marginRight: "5.88%",
            width: "88.24%",
            justifyContent: "center",
          }}
        >
          <View style={styles.banner}>
              <Image
                source="/energy_sense.png"
                style={{
                  width: 80,
                  height: 33,
                  float: "left !important",
                }}
              />
            <View style={styles.titleBoxContainer}>
              <Text style={{
                fontWeight: 500,
                fontSize: 10,
                color: "#233E86",
                 
              }}>Air Conditioning Rebate Program for Ductless Systems</Text>
              <Text style={{
                fontWeight: 500,
                fontSize: 13.5,
                color: "#233E86",
                 
              }}>Rebate Application for GPA Residential Customers</Text>
            </View>
              <Image
                source="/GPA_icon.png"
                style={{
                  width: 45,
                  height: 38,
                  float: "right !important",
                }}
              />
          </View>
        </View>
        <View
          style={{
            marginLeft: "5.88%",
            marginRight: "5.88%",
            marginBottom: "5.88",
            width: "88.24%",
          }}>
          <View style={styles.whiteSpace}/>
          <Text style={styles.text}>Please read the information on the reverse side before completing rebate application.</Text>
          <View style={styles.whiteSpace}/>
          <View style={styles.whiteSpace}/>
          <Text style={styles.title3}>Applicant Information:</Text>
          <View style={styles.whiteSpace}/>

          <View style={styles.textLine}>
            <View style={{width: "19%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>APPLICANT NAME: </Text>
            </View>
            <View style={{width:"46%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11, }}>{data?.Info_Customer_name || "N/A"}</Text>
            </View>
            <View style={{width: "17%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, TELEPHONE NO.: </Text>
            </View>
            <View style={{width: "18%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Tel_no || "N/A"}</Text>
            </View>
          </View>

          <View style={styles.textLine}>
            <View style={{width: "26%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>INSTALLATION ADDRESS: </Text>
            </View>
            <View style={{width: "74%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>
                {arr3 != null ? arr3.map((arr) => (<Text>{arr}</Text>)): "N/A"}
                </Text>
            </View>
          </View>

          <View style={styles.textLine}>
            <View style={{width: "5%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>CITY: </Text>
            </View>
            <View style={{width: "21%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{city_zipcode.find(
                        (p) => p._id === data?.Info_City_village
                      )
                        ? city_zipcode.find(
                            (p) => p._id === data?.Info_City_village
                          ).village
                        : "N/A" || "N/A"}</Text>
            </View>
            <View style={{width: "11%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, GUAM ZIP: </Text>
            </View>
            <View style={{width: "13%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Zipcode || "N/A"}</Text>
            </View>
            <View style={{width: "8%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, EMAIL: </Text>
            </View>
            <View style={{width: "42%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Email || "N/A"}</Text>
            </View>
          </View>
          
          <View style={styles.textLine}>
            <View style={{width: "14%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>CONTROL NO: </Text>
            </View>
            <View style={{width: "19%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Control_Number || "N/A"}</Text>
            </View>
            <View style={{width: "30%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, GPA ELECTRIC ACCOUNT NO: </Text>
            </View>
            <View style={{width: "21%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Account_no || "N/A"}</Text>
            </View>
            <View style={{width: "8%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, BILL ID: </Text>
            </View>
            <View style={{width: "8%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11,}}>{data?.Info_Bill_id || "N/A"}</Text>
            </View>
          </View>

          <View style={styles.whiteSpace}/>
          <View style={styles.whiteSpace}/>
          <Text style={styles.text}>Applicant must be either the GPA account holder or the property owner to claim a rebate. Is Applicant the owner of the residential property? {"\u00a0\u00a0\u00a0"}
          <StatusIcon check={data?.Info_Is_owner == "1" ? 1 : 0} /> <Text style={{
            fontSize: 10,
            color: "#333333",
            fontWeight: 500,
             
            }}>YES</Text> {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_Is_owner == "1" ? 0 : 1} /> <Text style={{
            fontSize: 10,
            color: "#333333",
            fontWeight: 500,
             
            }}>NO</Text></Text>
          
          <View style={styles.whiteSpace}/>
          <View style={styles.whiteSpace}/>
          <Text style={styles.text}>Exceptions may be made if the tenant or property owner representative provides an authorization letter with a copy of photo I.D.. Residential customers with Commercial Accounts must provide proof of residency in order to participate in this rebate program. Condominium or property managers may apply for tenants.</Text>
                      
          <View style={styles.whiteSpace}/>
          <View style={styles.whiteSpace}/>
          <View style={styles.textLine}>
            <View style={{width: "42%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>MAILING ADDRESS (if different than above): </Text>
            </View>
            <View style={{width: "58%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>
                {mailingAddressFix || "N/A"}
                </Text>
            </View>
          </View>

          <View style={styles.textLine}>
            <View style={{width: "12%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>COUNTRY: </Text>
            </View>
            <View style={{width: "31%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{countryFix || "N/A"}</Text>
            </View>
            <View style={{width: "7%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, CITY: </Text>
            </View>
            <View style={{width: "29%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Mailing_city || "N/A"}</Text>
            </View>
            <View style={{width: "11%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, ZIP CODE: </Text>
            </View>
            <View style={{width: "10%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Info_Mailing_zip || "N/A"}</Text>
            </View>
          </View>

          {data?.Type == "RESID"?
          <>
             <View style={styles.textLine}>
              <View style={{width: "27%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>HOME SIZE <Text style={styles.text}>(approx. sq. ft.)</Text>: </Text>
              </View>
              <View style={{width: "25%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}>{data?.Info_Home_size || "N/A"}</Text>
              </View>
              <View style={{width: "31%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>, HOME AGE <Text style={styles.text}>(approx. year built)</Text>: </Text>
              </View>
              <View style={{width: "17%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}>{data?.Info_Home_age || "N/A"}</Text>
              </View>
            </View>
          </>:
          <>
             <View style={styles.textLine}>
              <View style={{width: "30%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>BUILDING SIZE <Text style={styles.text}>(approx. sq. ft.)</Text>: </Text>
              </View>
              <View style={{width: "22%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}>{data?.Info_Home_size || "N/A"}</Text>
              </View>
              <View style={{width: "34%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>, BUILDING AGE <Text style={styles.text}>(approx. year built)</Text>: </Text>
              </View>
              <View style={{width: "14%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}>{data?.Info_Home_age || "N/A"}</Text>
              </View>
            </View>
          </>}

         

          <Text style={{
            fontSize: 10,
            color: "#333333",
            fontWeight: 500,
             
            }}>NEW CONSTRUCTION?
                                {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_New_construction == "true" ? 1 : 0} /> <Text style={{
            fontSize: 10,
            color: "#333333",
            fontWeight: 500,
             
            }}>YES</Text> 
                                {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_New_construction == "true" ? 0 : 1} /> <Text style={{
            fontSize: 10,
            color: "#333333",
            fontWeight: 500,
             
            }}>NO</Text>
          </Text>
          
          {data?.Type == "RESID"?
          <>
            <Text style={{
              fontSize: 10,
              color: "#333333",
              fontWeight: 500,
               
              }}>HOME TYPE <Text style={styles.text}>(check one)</Text>:
            </Text>
            <Text style={{textAlign: "center"}}>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "Single Family"} /> <Text style={styles.text}>SINGLE FAMILY</Text> 
              {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_Home_type == "Apartment"} /> <Text style={styles.text}>APARTMENT</Text>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "Condo"} /> <Text style={styles.text}>CONDO</Text> 
              {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_Home_type == "Mobile Home"} /> <Text style={styles.text}>MOBILE HOME</Text>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "Other"} /> <Text style={styles.text}>OTHER</Text>
            </Text>
          </> :
          <>
            <Text style={{
              fontSize: 10,
              color: "#333333",
              fontWeight: 500,
               
              }}>BUILDING TYPE <Text style={styles.text}>(check one)</Text>:
            </Text>
            <Text style={{textAlign: "center"}}>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "RETAIL"} /> <Text style={styles.text}>RETAIL</Text> 
              {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_Home_type == "OFFICE"} /> <Text style={styles.text}>OFFICE</Text>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "RELIGIOUS BUILDING"} /> <Text style={styles.text}>RELIGIOUS BUILDING</Text> 
              {"\u00a0\u00a0\u00a0"} <StatusIcon check={data?.Info_Home_type == "FINANCIAL INSTITUTION"} /> <Text style={styles.text}>FINANCIAL INSTITUTION</Text>
              {"\u00a0\u00a0\u00a0"}<StatusIcon check={data?.Info_Home_type == "Other"} /> <Text style={styles.text}>OTHER</Text>
            </Text>
          </>
          }
          

          <View style={styles.whiteSpace}/>
          <View style={styles.whiteSpace}/>
          <Text style={{
            fontWeight: 400,
            fontSize: 14,
            color: "#8BC440"
          }}>Installer Information:</Text>
          <View style={styles.whiteSpace}/>
          
          <View style={styles.textLine}>
            <View style={{width: "20%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>TECHNICIAN NAME: </Text>
            </View>
            <View style={{width: "39%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Installer_New_name || "N/A"}</Text>
            </View>
            <View style={{width: "20%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, WORK TELEPHONE: </Text>
            </View>
            <View style={{width: "21%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Installer_New_worktel || "N/A"}</Text>
            </View>
          </View>

          <View style={styles.textLine}>
            <View style={{width: "18%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>COMPANY NAME: </Text>
            </View>
            <View style={{width: "82%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Installer_New_companyname || "N/A"}</Text>
            </View>
          </View>

          <View style={styles.textLine}>
            <View style={{width: "30%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>DELAY FOR DATE OF PURCHASE: </Text>
            </View>
            <View style={{width: "37%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Delay_Reason || "N/A"}</Text>
            </View>
            <View style={{width: "20%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, CERTIFICATION NO: </Text>
            </View>
            <View style={{width: "13%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data.Installer_New_certno || "N/A"}</Text>
            </View>
          </View>
          
          <View style={styles.textLine}>
            <View style={{width: "7%", flexDirection: "row"}}>
              <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>EMAIL: </Text>
            </View>
            <View style={{width: "32%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data?.Installer_New_email || "N/A"}</Text>
            </View>
            <View style={{width: "16%", flexDirection: "row"}}>
              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                 
                }}>, DATE OF FINAL: </Text>
            </View>
            <View style={{width: "33%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}>{data.Installer_New_finaldate || "N/A"}</Text>
            </View>
          </View>
          <View style={styles.textLine}>
            <View style={{width: "34%", flexDirection: "row"}}>
              <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>DELAY FOR FINAL INSTALLATION: </Text>
            </View>
            <View style={{width: "50%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
              <Text style={{  fontSize:11}}> 
                {data?.Delay_Reason2 || "N/A"}
              </Text>
            </View>
          </View>
        
        </View>
      </Page>

      {(() => {
        let rows = [];
        let current = 0;
        let sliceParams1 = 0;
        let sliceParams2 = 3;
        for (let i = 0; i < newEquipmentPageCount; i++) {
          rows.push(
            <Page size="LETTER" key={i}>
            <View
              style={{
                marginTop: "5.88%",
                marginLeft: "5.88%",
                marginRight: "5.88%",
                width: "88.24%",
                justifyContent: "center",
              }}
            >
              <View style={styles.banner}>
                <Image
                  source="/energy_sense.png"
                  style={{
                    width: 80,
                    height: 33,
                    float: "left !important",
                  }}
                />
                <View style={styles.titleBoxContainer}>
                  <Text style={{
                    fontWeight: 500,
                    fontSize: 10,
                    color: "#233E86",
                     
                  }}>Air Conditioning Rebate Program for Ductless Systems</Text>
                  <Text style={{
                    fontWeight: 500,
                    fontSize: 13.5,
                    color: "#233E86",
                     
                  }}>Rebate Application for GPA Residential Customers</Text>
                </View>
                  <Image
                    source="/GPA_icon.png"
                    style={{
                      width: 45,
                      height: 38,
                      float: "right !important",
                    }}
                  />
              </View>
            </View>
            <View
              style={{
                marginLeft: "5.88%",
                marginRight: "5.88%",
                marginBottom: "5.88",
                width: "88.24%",
                justifyContent: "center",
              }}
            >
              {current == 0 ? <>
                <Text style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#8BC440"
                }}>New Equipment Information:</Text>
                <Text style={styles.text}>If Qty more than 1, provide data for all units installed. Provide Model #s for both indoor and outdoor units.</Text>
                <View style={styles.whiteSpace}/>
                </> : null }

              {data?.New_equipment.length <= 0 ? 

              <Text style={{
                fontSize: 10,
                color: "#333333",
                fontWeight: 500,
                fontStyle: "normal",
                 
                textAlign: "center"
              }}>-NO DATA FOR NEW EQUIPMENT-</Text>:
              <>
                <View style={styles.mainTable}>
                  <View style={styles.columns}>
                    <View style={styles.tableCellEmpty}>
                      <Text> </Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                      
                    }}>
                      <Text>System Type</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Vendor</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Manufacturer</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Model Number</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Quantity</Text>
                    </View>
                    {/* {console.log(data.New_equipment[0].newEquip_System_type)} */}
                    {data?.New_equipment[0].newEquip_System_type == "Washer" || data?.New_equipment[0].newEquip_System_type == "Dryer" ?
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Cubic Feet</Text>
                    </View> :
                    <>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>TONS</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>BTU</Text>
                      </View>
                    </>
                    }
                    
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Invoice</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Purchase Date</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Install Date</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Delay Reason for </Text>
                      <Text>Final Installation</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Delay Reason for </Text>
                      <Text>Purchase Date</Text>
                    </View>
                    <View style={{
                      backgroundColor: "#223F89",
                      padding: 3,
                      margin: 0.5,
                      color: "white",
                       
                      fontSize: 10,
                      fontWeight: 500,
                      height: 40,
                      justifyContent: "center"
                    }}>
                      <Text>Rebate</Text>
                    </View>  
                  </View>
                  {data?.New_equipment.slice(sliceParams1, sliceParams2).map((value, index) => {
                    totalRebate2 = value?.newEquip_rebate + totalRebate2;
                    return (
                      <>
                        <NewEquipmentTable
                          key={index}
                          finalDate={data?.Installer_New_finaldate}
                          data={value}
                          Delay_Reason ={data?.Delay_Reason}
                          Delay_Reason2 = {data?.Delay_Reason2}
                          index={index}
                          current={current}
                        />
                      </>
                    );
                  })}
                  
                </View>
              </>
              }
             </View>
            </Page>
          );
          current = current + 3;
          sliceParams1 = sliceParams1 + 3;
          sliceParams2 = sliceParams2 + 3;
          setNewEquipmentCurrent(current)
        }
        return rows;
      })()}

      {(() => {
        let rows = [];
        let current = 0;
        let sliceParams1 = 0;
        let sliceParams2 = 3;
        for (let i = 0; i < newEquipmentPageCount; i++) {
          rows.push(
            <Page size="LETTER">
              <View
                  style={{
                    marginTop: "5.88%",
                    marginLeft: "5.88%",
                    marginRight: "5.88%",
                    width: "88.24%",
                    justifyContent: "center",
                  }}
                >
                  <View style={styles.banner}>
                      <Image
                        source="/energy_sense.png"
                        style={{
                          width: 80,
                          height: 33,
                          float: "left !important",
                        }}
                      />
                    <View style={styles.titleBoxContainer}>
                      <Text style={{
                        fontWeight: 500,
                        fontSize: 10,
                        color: "#233E86",
                         
                      }}>Air Conditioning Rebate Program for Ductless Systems</Text>
                      <Text style={{
                        fontWeight: 500,
                        fontSize: 13.5,
                        color: "#233E86",
                         
                      }}>Rebate Application for GPA Residential Customers</Text>
                    </View>
                      <Image
                        source="/GPA_icon.png"
                        style={{
                          width: 45,
                          height: 38,
                          float: "right !important",
                        }}
                      />
                  </View>
                </View>
              <View
                style={{
                  marginLeft: "5.88%",
                  marginRight: "5.88%",
                  marginBottom: "5.88",
                  width: "88.24%",
                  justifyContent: "center",
                }}
              >
                {current == 0 ? <>
                  <Text style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#8BC440"
                  }}>Existing/Old Equipment Information:</Text>
                  <Text style={styles.text}>Existing/old air conditioning (AC) equipment must be removed and properly disposed. Old equipment must be
                  disposed of by either the Installer or the Applicant with Guam Solid Waste Authority (GSWA) or Guam
                  Environmental Protection Agency (GEPA) listed recycler. If Applicant is disposing, there must be a receipt of
                  disposal from GSWA or the recycler attached to this rebate form.</Text>
                  <View style={styles.whiteSpace}/>
                  </> : null}
                
                
                {data?.Old_equipment.length <= 0 ? 
                <>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                  fontStyle: "normal",
                   
                  textAlign: "center"
                }}>-NO DATA FOR OLD EQUIPMENT-</Text>
                
                <View style={styles.whiteSpace}/>
                <View style={styles.whiteSpace}/>
                <View style={styles.whiteSpace}/>

                <Text style={styles.text}>1 Seasonal Energy Efficiency Ratio</Text>
                  <Text style={styles.text}>INSTALLER SIGNATURE FOR DISPOSAL (must be same as above):</Text>
                  <View style={styles.textLine}>
                    <View style={{width: "35%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                      <Text style={{  fontSize:11}}> </Text>
                    </View>
                    <View style={{width: "8%", flexDirection: "row"}}>
                      <Text style={{
                        fontSize: 10,
                        color: "#333333",
                        fontWeight: 500,
                         
                        }}>, DATE: </Text>
                    </View>
                    <View style={{width: "20%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                      <Text style={{  fontSize:11}}> </Text>
                    </View>
                  </View>
                  <View style={styles.whiteSpace}/>
                  <Text style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#8BC440"
                  }}>Acceptance of Terms and Conditions:</Text>
                  <Text style={styles.text}>Installer certifies equipment will be properly disposed with a GEPA listed recycler or GSWA.</Text>
                  <View style={styles.whiteSpace}/>
                  
                    <Text style={styles.text}>I hereby certify that I am the GPA electric account owner and/or the owner of the residential property at which the
                    service/installation occured, that I have purchased the equipment described on this Rebate Application, and that it 
                    has been installed at the indicated installation address. I have read the Terms and Conditions on the reverse side of
                    this form and acknowledge that GPA may verify the information provided. <Text style={{textDecoration: "underline"}}>I acknowledge a copy of the itemized
                    sales receipt with the date of purchase must accompany this form. If an Installer is not disposing of the equipment, a
                    disposal receipt must accompany this form.</Text></Text>
                  
                  

                  <View style={styles.whiteSpace}/>
                  <View style={styles.whiteSpace}/>

                  <View style={styles.textLine}>
                    <View style={{width: "24%", flexDirection: "row"}}>
                      <Text style={{
                          fontSize: 10,
                          color: "#333333",
                          fontWeight: 500,
                           
                          }}>APPLICANT SIGNATURE: </Text>
                    </View>
                    <View style={{width: "35%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                      <Text style={{  fontSize:11}}> </Text>
                    </View>
                    <View style={{width: "8%", flexDirection: "row"}}>
                      <Text style={{
                        fontSize: 10,
                        color: "#333333",
                        fontWeight: 500,
                         
                        }}>, DATE: </Text>
                    </View>
                    <View style={{width: "15%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                      <Text style={{  fontSize:11}}> </Text>
                    </View>
                  </View>

                </>:
                
                <>
                  <View style={styles.mainTable}>
                    <View style={styles.columns}>
                      <View style={styles.tableCellEmpty}>
                        <Text> </Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>System Type</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Tons</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>BTU</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Quantity</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Number of Years</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Equipment Condition</Text>
                        <Text>Prior to Removal</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Seer</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Disposal Party</Text>
                      </View>
                      <View style={{
                        backgroundColor: "#223F89",
                        padding: 3,
                        margin: 0.5,
                        color: "white",
                         
                        fontSize: 10,
                        fontWeight: 500,
                        height: 40,
                        justifyContent: "center"
                      }}>
                        <Text>Date</Text>
                      </View>
                    </View>
                    {data?.Old_equipment.slice(sliceParams1, sliceParams2).map((value, index) => {
                      totalRebate2 = value?.newEquip_rebate + totalRebate2;
                      return (
                        <>
                          <OldEquipmentTable
                            key={index}
                            data={value}
                            index={index}
                            current={current}
                          />
                        </>
                      );
                    })}
                    
                  </View>
                </>
                }
              </View>
            </Page>
          );
          current = current + 3;
          sliceParams1 = sliceParams1 + 3;
          sliceParams2 = sliceParams2 + 3;
          setOldEquipmentCurrent(current)
        }
        return rows;
      })()}

          
      

      {data?.Old_equipment.length <= 0 ? null :
      <>
      <Page size="LETTER">
      <View
            style={{
              marginTop: "5.88%",
              marginLeft: "5.88%",
              marginRight: "5.88%",
              width: "88.24%",
              justifyContent: "center",
            }}
          >
            <View style={styles.banner}>
                <Image
                  source="/energy_sense.png"
                  style={{
                    width: 80,
                    height: 33,
                    float: "left !important",
                  }}
                />
              <View style={styles.titleBoxContainer}>
                <Text style={{
                  fontWeight: 500,
                  fontSize: 10,
                  color: "#233E86",
                   
                }}>Air Conditioning Rebate Program for Ductless Systems</Text>
                <Text style={{
                  fontWeight: 500,
                  fontSize: 13.5,
                  color: "#233E86",
                   
                }}>Rebate Application for GPA Residential Customers</Text>
              </View>
                <Image
                  source="/GPA_icon.png"
                  style={{
                    width: 45,
                    height: 38,
                    float: "right !important",
                  }}
                />
            </View>
          </View>
        <View
              style={{
                marginLeft: "5.88%",
                marginRight: "5.88%",
                width: "88.24%",
                justifyContent: "center",
              }}
            >
            <Text style={styles.text}>1 Seasonal Energy Efficiency Ratio</Text>
            <Text style={styles.text}>INSTALLER SIGNATURE FOR DISPOSAL (must be same as above):</Text>
            <View style={styles.textLine}>
              <View style={{width: "35%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}> </Text>
              </View>
              <View style={{width: "8%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>, DATE: </Text>
              </View>
              <View style={{width: "20%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}> </Text>
              </View>
            </View>
            <View style={styles.whiteSpace}/>
            <Text style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#8BC440"
            }}>Acceptance of Terms and Conditions:</Text>
            <Text style={styles.text}>Installer certifies equipment will be properly disposed with a GEPA listed recycler or GSWA.</Text>
            <View style={styles.whiteSpace}/>
            <Text style={styles.text}>I hereby certify that I am the GPA electric account owner and/or the owner of the residential property at which the
            service/installation occured, that I have purchased the equipment described on this Rebate Application, and that it 
            has been installed at the indicated installation address. I have read the Terms and Conditions on the reverse side of
            this form and acknowledge that GPA may verify the information provided. <Text style={{textDecoration: "underline"}}>I acknowledge a copy of the itemized
            sales receipt with the date of purchase must accompany this form. If an Installer is not disposing of the equipment, a
            disposal receipt must accompany this form.</Text></Text>

            <View style={styles.whiteSpace}/>
            <View style={styles.whiteSpace}/>

            <View style={styles.textLine}>
              <View style={{width: "24%", flexDirection: "row"}}>
                <Text style={{
                    fontSize: 10,
                    color: "#333333",
                    fontWeight: 500,
                     
                    }}>APPLICANT SIGNATURE: </Text>
              </View>
              <View style={{width: "35%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}> </Text>
              </View>
              <View style={{width: "8%", flexDirection: "row"}}>
                <Text style={{
                  fontSize: 10,
                  color: "#333333",
                  fontWeight: 500,
                   
                  }}>, DATE: </Text>
              </View>
              <View style={{width: "15%", flexDirection: "row", justifyContent: "center", borderBottom: 1}}>
                <Text style={{  fontSize:11}}> </Text>
              </View>
            </View>
        </View>
      </Page>
      </>}

    </Document>
  )

  useEffect(() => {
    if(data?.Info_Mailing_address){
      const words = data.Info_Mailing_address.split("^")
      setMailingAddressFix(words[0])
      setCountryFix(words[1] ? words[1] : "N/A")
    }
 }, [data]);

  return (
    <>
      <CustomerHeader />
      <div>
        <Container>
          <center>
            <Col md={11} sm={11} xs={11} className="text-center mt-4 position-relative main-content">
              <Row className="mx-0 flex justify-content-center">
              <h3
                  style={{ marginBottom: 30 }}
                  className="text-center text-info"
                  id="trackTitle"
                >
                  PRINT YOUR APPLICATION
              </h3> 

              <BrowserView>
                <PDFViewer width={"100%"} height={"600"} showToolbar={false}>
                  <PrintApp/>
                </PDFViewer>
                <PDFDownloadLink
                    style={{width: "50%"}}
                    className="btn btn-info mb-2 mt-2 btn-sm"
                    document={ <PrintApp/>}
                    fileName={`${data?.Control_Number}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download PDF"
                    }
                </PDFDownloadLink>
              </BrowserView>

              <MobileView>
                <Row>
                  <h6 style={{color: "#223e87"}}>Download your PDF</h6> 
                  <p>you can now download your application's pdf file using the button below</p>
                </Row>
                
                <Row>
                  <center>
                    <PDFDownloadLink
                        style={{width: "50%"}}
                        className="btn btn-info mb-2 mt-2 btn-sm"
                        document={ <PrintApp/>}
                        fileName={`${data?.Control_Number}.pdf`}
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download PDF"
                        }
                    </PDFDownloadLink>
                  </center>
                </Row>
                
              </MobileView>

            
              
              <Link to={`/`} className="text-success px-5">
                <h4
                  style={{ marginTop: 50 }}
                  className="text-center fs-5"
                  id="trackBackBtn"
                >
                  Back to Homepage
                </h4>
              </Link>
                
                <Row>
                  <br />
                  <small
                    className="text-secondary text-center"
                    id="trackFooter"
                    style={{ marginTop: 60 }}
                  >
                    Energy Sense Rebate Program <br />
                    Copyright &copy; 2022 GPA Powered By Xtendly
                  </small>
                </Row>
            
              </Row>
            </Col>

          </center>
        
        </Container>
      </div>
    </>
  );
}

export default PrintApplicationSummary2;
