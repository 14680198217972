import axios from "axios";
import {
  APPLICATION_LIST_REQUEST,
  APPLICATION_LIST_SUCCESS,
  APPLICATION_LIST_FAIL,
  APPLICATION_LIST_RESET,
  APPLICATION_LIST_RECORD_REQUEST,
  APPLICATION_LIST_RECORD_SUCCESS,
  APPLICATION_LIST_RECORD_FAIL,
  APPLICATION_LIST_RECORD_RESET,
  APPLICATION_DETAIL_REQUEST,
  APPLICATION_DETAIL_SUCCESS,
  APPLICATION_DETAIL_FAIL,
  APPLICATION_COMMENTS_REQUEST,
  APPLICATION_COMMENTS_SUCCESS,
  APPLICATION_COMMENTS_FAIL,
  APPLICATION_COMMENTS_RESET,
  APPLICATION_LOGS_REQUEST,
  APPLICATION_LOGS_SUCCESS,
  APPLICATION_LOGS_FAIL,
  APPLICATION_LOGS_RESET,
  APPLICATION_UPDATE_REQUEST,
  APPLICATION_UPDATE_SUCCESS,
  APPLICATION_UPDATE_FAIL,
  APPLICATION_EDIT_REQUEST,
  APPLICATION_EDIT_SUCCESS,
  APPLICATION_EDIT_FAIL,
  APPLICATION_EQUIP_EDIT_REQUEST,
  APPLICATION_EQUIP_EDIT_SUCCESS,
  APPLICATION_EQUIP_EDIT_FAIL,
  APPLICATION_EQUIP_DELETE_REQUEST,
  APPLICATION_EQUIP_DELETE_SUCCESS,
  APPLICATION_EQUIP_DELETE_FAIL,
  APPLICATION_TRACK_REQUEST,
  APPLICATION_TRACK_SUCCESS,
  APPLICATION_TRACK_FAIL,
  COMMENT_ADD_REQUEST,
  COMMENT_ADD_SUCCESS,
  COMMENT_ADD_FAIL,
  APPLICATION_DETAIL_PRINT_REQUEST,
  APPLICATION_DETAIL_PRINT_SUCCESS,
  APPLICATION_DETAIL_PRINT_FAIL,
  APPLICATION_DETAIL_PRINT_RESET,
} from "../constants/applicationConstants";

import { USER_LOGOUT } from "../constants/userConstants";

console.log(`This is the host ${window.location.hostname}`);
let URL = window.location.hostname.includes(".xtendly") || window.location.hostname.includes("localhost")
  ? process.env.REACT_APP_API_BASE_URL_DEV
  : process.env.REACT_APP_API_BASE_URL;

export const trackApplications = (control_no) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPLICATION_TRACK_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      URL + "/view-application",
      { controlNo: control_no },
      config
    );

    dispatch({
      type: APPLICATION_TRACK_SUCCESS,
      payload: data.table[0],
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_TRACK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const listApplications = (page) => async (dispatch, getState) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/application-list?page=" + page,
      { roleId: obj.message.original.roleId },
      config
    );

    dispatch({
      type: APPLICATION_LIST_SUCCESS,
      payload: data.table,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const listApplicationsPaginated = (
  page,
  controlNumAsc,
  controlNumType,
  controlNumSelect1,
  controlNumSelect2,
  controlNumKeyword1,
  controlNumKeyword2,
  batchCodeAsc,
  batchCodeType,
  batchCodeSelect1,
  batchCodeSelect2,
  batchCodeKeyword1,
  batchCodeKeyword2,
  nameAsc,
  nameType,
  nameSelect1,
  nameSelect2,
  nameKeyword1,
  nameKeyword2,
  appDateAsc,
  appDateType,
  appDateSelect1,
  appDateSelect2,
  appDateKeyword1,
  appDateKeyword2,
  stageAsc,
  stageType,
  stageSelect1,
  stageSelect2,
  stageKeyword1,
  stageKeyword2,
  statusAsc,
  statusType,
  statusSelect1,
  statusSelect2,
  statusKeyword1,
  statusKeyword2,
  systemTypeAsc,
  systemTypeType,
  systemTypeSelect1,
  systemTypeSelect2,
  systemTypeKeyword1,
  systemTypeKeyword2,
) => async (dispatch, getState) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/application-list?page=" + page,
      {
        roleId: obj.message.original.roleId,
        ControlNumber: {
          query1: controlNumKeyword1,
          query2: controlNumKeyword2,
          filterType1: controlNumSelect1,
          filterType2: controlNumSelect2,
          queryType: controlNumType,
          isAscending: controlNumAsc,
        },
        BatchCode: {
          query1: batchCodeKeyword1,
          query2: batchCodeKeyword2,
          filterType1: batchCodeSelect1,
          filterType2: batchCodeSelect2,
          queryType: batchCodeType,
          isAscending: batchCodeAsc,
        },
        Name: {
          query1: nameKeyword1,
          query2: nameKeyword2,
          filterType1: nameSelect1,
          filterType2: nameSelect2,
          queryType: nameType,
          isAscending: nameAsc,
        },
        ApplicationDate: {
          query1: appDateKeyword1,
          query2: appDateKeyword2,
          filterType1: appDateSelect1,
          filterType2: appDateSelect2,
          queryType: appDateType,
          isAscending: appDateAsc,
        },
        Stage: {
          query1: stageKeyword1,
          query2: stageKeyword2,
          filterType1: stageSelect1,
          filterType2: stageSelect2,
          queryType: stageType,
          isAscending: stageAsc,
        },
        Status: {
          query1: statusKeyword1,
          query2: statusKeyword2,
          filterType1: statusSelect1,
          filterType2: statusSelect2,
          queryType: statusType,
          isAscending: statusAsc,
        },
        SystemType: {
          query1: systemTypeKeyword1,
          query2: systemTypeKeyword2,
          filterType1: systemTypeSelect1,
          filterType2: systemTypeSelect2,
          queryType: systemTypeType,
          isAscending: systemTypeAsc,
        },
      },
      config
    );

    // Store page data
    localStorage.setItem('ApplicationsCount', data?.pagination?.lastPage?.toString() ?? "null");

    dispatch({
      type: APPLICATION_LIST_SUCCESS,
      payload: data.table,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const listApplicationsRecords = (page) => async (dispatch, getState) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_LIST_RECORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(URL + "/records?page=" + page, { roleId: 1 }, config);

    dispatch({
      type: APPLICATION_LIST_RECORD_SUCCESS,
      payload: data.table,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_LIST_RECORD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const listApplicationsRecordsPaginated = (
  page,
  controlNumAsc,
  controlNumType,
  controlNumSelect1,
  controlNumSelect2,
  controlNumKeyword1,
  controlNumKeyword2,
  batchCodeAsc,
  batchCodeType,
  batchCodeSelect1,
  batchCodeSelect2,
  batchCodeKeyword1,
  batchCodeKeyword2,
  nameAsc,
  nameType,
  nameSelect1,
  nameSelect2,
  nameKeyword1,
  nameKeyword2,
  appDateAsc,
  appDateType,
  appDateSelect1,
  appDateSelect2,
  appDateKeyword1,
  appDateKeyword2,
  stageAsc,
  stageType,
  stageSelect1,
  stageSelect2,
  stageKeyword1,
  stageKeyword2,
  statusAsc,
  statusType,
  statusSelect1,
  statusSelect2,
  statusKeyword1,
  statusKeyword2,
  systemTypeAsc,
  systemTypeType,
  systemTypeSelect1,
  systemTypeSelect2,
  systemTypeKeyword1,
  systemTypeKeyword2,
) => async (dispatch, getState) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_LIST_RECORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/records?page=" + page,
      {
        roleId: 1,
        ControlNumber: {
          query1: controlNumKeyword1,
          query2: controlNumKeyword2,
          filterType1: controlNumSelect1,
          filterType2: controlNumSelect2,
          queryType: controlNumType,
          isAscending: controlNumAsc,
        },
        BatchCode: {
          query1: batchCodeKeyword1,
          query2: batchCodeKeyword2,
          filterType1: batchCodeSelect1,
          filterType2: batchCodeSelect2,
          queryType: batchCodeType,
          isAscending: batchCodeAsc,
        },
        Name: {
          query1: nameKeyword1,
          query2: nameKeyword2,
          filterType1: nameSelect1,
          filterType2: nameSelect2,
          queryType: nameType,
          isAscending: nameAsc,
        },
        ApplicationDate: {
          query1: appDateKeyword1,
          query2: appDateKeyword2,
          filterType1: appDateSelect1,
          filterType2: appDateSelect2,
          queryType: appDateType,
          isAscending: appDateAsc,
        },
        Stage: {
          query1: stageKeyword1,
          query2: stageKeyword2,
          filterType1: stageSelect1,
          filterType2: stageSelect2,
          queryType: stageType,
          isAscending: stageAsc,
        },
        Status: {
          query1: statusKeyword1,
          query2: statusKeyword2,
          filterType1: statusSelect1,
          filterType2: statusSelect2,
          queryType: statusType,
          isAscending: statusAsc,
        },
        SystemType: {
          query1: systemTypeKeyword1,
          query2: systemTypeKeyword2,
          filterType1: systemTypeSelect1,
          filterType2: systemTypeSelect2,
          queryType: systemTypeType,
          isAscending: systemTypeAsc,
        },
      },
      config
    );

    // Store page data
    localStorage.setItem('RecordsCount', data?.pagination?.lastPage?.toString() ?? "null");

    dispatch({
      type: APPLICATION_LIST_RECORD_SUCCESS,
      payload: data.table,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_LIST_RECORD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const logsApplication =
  (application_id) => async (dispatch, getState) => {
    try {
      let obj = JSON.parse(localStorage.getItem("userInfo"));

      dispatch({
        type: APPLICATION_LOGS_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${obj.message.original.access_token}`,
        },
      };

      const { data } = await axios.post(
        URL + "/log-list",
        { applicationId: application_id },
        config
      );

      dispatch({
        type: APPLICATION_LOGS_SUCCESS,
        payload: data.table,
      });
    } catch (error) {
      dispatch({
        type: APPLICATION_LOGS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.reponse.data.detail
            : error.message,
      });
    }
  };

export const commentsApplication =
  (application_id) => async (dispatch, getState) => {
    try {
      let obj = JSON.parse(localStorage.getItem("userInfo"));

      dispatch({
        type: APPLICATION_COMMENTS_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${obj.message.original.access_token}`,
        },
      };

      const { data } = await axios.post(
        URL + "/comment-list",
        { applicationId: application_id },
        config
      );

      dispatch({
        type: APPLICATION_COMMENTS_SUCCESS,
        payload: data.table,
      });
    } catch (error) {
      dispatch({
        type: APPLICATION_COMMENTS_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.reponse.data.detail
            : error.message,
      });
    }
  };

export const detailApplication = (application_id) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_DETAIL_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/view-information",
      { applicationId: application_id },
      config
    );

    dispatch({
      type: APPLICATION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const printDetailApplication = (controlNo) => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATION_DETAIL_PRINT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      URL + "/print",
      { controlNo: controlNo },
      config
    );

    dispatch({
      type: APPLICATION_DETAIL_PRINT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPLICATION_DETAIL_PRINT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.reponse.data.detail
          : error.message,
    });
  }
};

export const addCommentAction =
  (applicationId, comment) => async (dispatch) => {
    try {
      let obj = JSON.parse(localStorage.getItem("userInfo"));

      dispatch({
        type: COMMENT_ADD_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${obj.message.original.access_token}`,
        },
      };

      const { data } = await axios.post(
        URL + "/add-comment",
        {
          applicationId: applicationId,
          UserId: obj.message.original.details.id,
          comment: comment,
        },
        config
      );

      dispatch({
        type: COMMENT_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMMENT_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editCommentAction = (applicationId, comment, commentId) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: COMMENT_ADD_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/edit-comment",
      {
        id : commentId,
        applicationId: applicationId,
        UserId: obj.message.original.details.id,
        comment: comment,
      },
      config
    );

    dispatch({
      type: COMMENT_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMMENT_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}

export const updateBatchApplication =
  (applicationId, status, stage, reason, batchId) => async (dispatch) => {
    try {
      let obj = JSON.parse(localStorage.getItem("userInfo"));

      dispatch({
        type: APPLICATION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${obj.message.original.access_token}`,
        },
      };

      const { data } = await axios.post(
        URL + "/batch-update",
        {
          applicationId: applicationId,
          UserId: obj.message.original.details.id,
          status: status,
          stage: stage,
          reasonId: parseInt(reason),
          batchId: batchId,
        },
        config
      );

      dispatch({
        type: APPLICATION_UPDATE_SUCCESS,
        payload: data,
      });

      // localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: APPLICATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateApplication =
  (applicationId, status, stage, reason, batchId) => async (dispatch) => {
    try {
      let obj = JSON.parse(localStorage.getItem("userInfo"));

      dispatch({
        type: APPLICATION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${obj.message.original.access_token}`,
        },
      };

      const { data } = await axios.post(
        URL + "/update-status",
        {
          applicationId: applicationId,
          UserId: obj.message.original.details.id,
          status: status,
          stage: stage,
          reasonId: parseInt(reason),
          batchId: batchId,
        },
        config
      );

      dispatch({
        type: APPLICATION_UPDATE_SUCCESS,
        payload: data,
      });

      // localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: APPLICATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const editApplication = (personal_info) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EDIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/edit-information",
      personal_info,
      config
    );

    dispatch({
      type: APPLICATION_EDIT_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EDIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const addNewEquipment = (equipment_info) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_EDIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/equipments-add",
      equipment_info,
      config
    );

    dispatch({
      type: APPLICATION_EQUIP_EDIT_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EQUIP_EDIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const addOldEquipment = (equipment_info) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_EDIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/old-equipments-add",
      equipment_info,
      config
    );

    dispatch({
      type: APPLICATION_EQUIP_EDIT_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EQUIP_EDIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const editEquipment = (equipment_info) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_EDIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/edit-equipment",
      equipment_info,
      config
    );

    dispatch({
      type: APPLICATION_EQUIP_EDIT_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EQUIP_EDIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateMaxInvoice = (application_info) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_EDIT_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    const { data } = await axios.post(
      URL + "/add-invoice-qty",
      application_info,
      config
    );

    dispatch({
      type: APPLICATION_EDIT_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EDIT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
}

export const deleteEquipment = (equipment_id, application_id, user_id ) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    console.log("config", config)

    const { data } = await axios.post(
      URL + "/equipments-delete",
      {
        id: equipment_id,
        applicationId: application_id,
        UserId: user_id
      },
      config
    );

    dispatch({
      type: APPLICATION_EQUIP_DELETE_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EQUIP_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteOldEquipment = (equipment_id, application_id, user_id ) => async (dispatch) => {
  try {
    let obj = JSON.parse(localStorage.getItem("userInfo"));

    dispatch({
      type: APPLICATION_EQUIP_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${obj.message.original.access_token}`,
      },
    };

    console.log("config", config)

    const { data } = await axios.post(
      URL + "/old-equipments-delete",
      {
        id: equipment_id,
        applicationId: application_id,
        UserId: user_id
      },
      config
    );

    dispatch({
      type: APPLICATION_EQUIP_DELETE_SUCCESS,
      payload: data,
    });

    // localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: APPLICATION_EQUIP_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: APPLICATION_LIST_RESET });
  dispatch({ type: APPLICATION_COMMENTS_RESET });
  dispatch({ type: APPLICATION_LOGS_RESET });
  dispatch({ type: APPLICATION_LIST_RECORD_RESET });
  dispatch({ type: APPLICATION_DETAIL_PRINT_RESET });
};
