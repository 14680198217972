export const BATCH_ADD_REQUEST = "BATCH_ADD_REQUEST";
export const BATCH_ADD_SUCCESS = "BATCH_ADD_SUCCESS";
export const BATCH_ADD_FAIL = "BATCH_ADD_FAIL";

export const BATCH_LIST_REQUEST = "BATCH_LIST_REQUEST";
export const BATCH_LIST_SUCCESS = "BATCH_LIST_SUCCESS";
export const BATCH_LIST_FAIL = "BATCH_LIST_FAIL";
export const BATCH_LIST_RESET = "BATCH_LIST_RESET";

export const BATCH_CURRENT_REQUEST = "BATCH_CURRENT_REQUEST";
export const BATCH_CURRENT_SUCCESS = "BATCH_CURRENT_SUCCESS";
export const BATCH_CURRENT_FAIL = "BATCH_CURRENT_FAIL";

export const BATCH_APPLICATION_REQUEST = "BATCH_APPLICATION_REQUEST";
export const BATCH_APPLICATION_SUCCESS = "BATCH_APPLICATION_SUCCESS";
export const BATCH_APPLICATION_FAIL = "BATCH_APPLICATION_FAIL";
export const BATCH_APPLICATION_RESET = "BATCH_APPLICATION_RESET";
