const city_zipcode = [
  {
    _id: "1",
    village: "AGANA HEIGHTS",
    zip_code: "96910",
  },
  {
    _id: "2",
    village: "AGAT",
    zip_code: "96915",
  },
  {
    _id: "3",
    village: "ASAN",
    zip_code: "96910",
  },
  {
    _id: "4",
    village: "MAINA",
    zip_code: "96910",
  },
  {
    _id: "5",
    village: "BARRIGADA",
    zip_code: "96913",
  },
  {
    _id: "6",
    village: "CHALAN PAGO",
    zip_code: "96910",
  },
  {
    _id: "7",
    village: "ORDOT",
    zip_code: "96910",
  },
  {
    _id: "8",
    village: "DEDEDO",
    zip_code: "96929",
  },
  {
    _id: "9",
    village: "HAGATNA",
    zip_code: "96910",
  },
  {
    _id: "10",
    village: "INARAJAN",
    zip_code: "96915",
  },
  {
    _id: "11",
    village: "MANGILAO",
    zip_code: "96913",
  },
  {
    _id: "12",
    village: "MERIZO",
    zip_code: "96915",
  },
  {
    _id: "13",
    village: "MONGMONG",
    zip_code: "96910",
  },
  {
    _id: "14",
    village: "TOTO",
    zip_code: "96910",
  },
  {
    _id: "15",
    village: "MAITE",
    zip_code: "96910",
  },
  {
    _id: "16",
    village: "PITI",
    zip_code: "96915",
  },
  {
    _id: "17",
    village: "SANTA RITA",
    zip_code: "96915",
  },
  {
    _id: "18",
    village: "SINAJANA",
    zip_code: "96910",
  },
  {
    _id: "19",
    village: "TALOFOFO",
    zip_code: "96915",
  },
  {
    _id: "20",
    village: "TAMUNING",
    zip_code: "96913",
  },
  {
    _id: "21",
    village: "TUMON",
    zip_code: "96913",
  },
  {
    _id: "22",
    village: "HARMON",
    zip_code: "96913",
  },
  {
    _id: "23",
    village: "UMATAC",
    zip_code: "96915",
  },
  {
    _id: "24",
    village: "YIGO",
    zip_code: "96929",
  },
  {
    _id: "25",
    village: "YONA",
    zip_code: "96915",
  },
];

export default city_zipcode;
