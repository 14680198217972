import React, {useEffect, useState} from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";

import "./HomeScreen.css";

function iOS() {

  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function  HomeScreen() {
  let history = useHistory();
  const [isIOS, setIsIOS] = useState(false);
  
  
  useEffect(()=>{
    if (iOS()) {
      setIsIOS(true);
    }
    history.push('/');
  }, []);

  return (
    <Row md={12} xs={12} sm={3} id="homeScreen" style={{justifyContent:"center"}}>
      <Col
        className="text-center mt-4 position-relative main-content"
        md={11}
        sm={11}
      >
        <Row className="mx-0 flex justify-content-center">
          <Row>
            <Col sm={4} md={4}></Col>
            <Col sm={4} md={4}>
              <Image src="/icon.png" className="mb-3" fluid />
            </Col>
            <Col sm={4} md={4}></Col>
          </Row>
          <Row style={{justifyContent: "center"}}>
            <Col sm={10} md={10}>
              <h2 className="mb-4 text-white" id="applyHead">
                APPLY FOR THE GPA ENERGY SENSE REBATE
              </h2>
            </Col>
          </Row>
          <Row style={{justifyContent: "center"}}>
            <Col sm={7} md={7} lg={7}>
              <p className="text-justify mb-4 text-white" id="applyCaption">
                Before you submit your rebate application please be sure to have
                all documents and information ready. If you have any questions
                you may contact us at <b>(671)647-5787 /8/9</b> or email us at{" "}
                <b>energysense@gpagwa.com</b>, visit our webpage at{" "}
                <a
                  className="text-white"
                  href="https://guampowerauthority.com/energysense"
                >
                  <u>Energy Sense Website</u>
                </a>
              </p>
            </Col>
          </Row>
          <Link
            to={`/application`}
            className="btn btn-success btn-lg px-5 mb-4"
            id="applyOnlineBtn"
          >
            <h4 className="mb-0 p-1">Apply Online</h4>
          </Link>
          {isIOS ? (
            <p className="text-white">
              *Please be assisted that IOS cannot be used to Register an
              Application. Please use android mobile or web browser on computer
              to register
            </p>
          ) : null}
          <br />
          <p className="mb-3">
            <Link to={`/track`} className="text-white">
              <u>TRACK YOUR APPLICATION</u>
            </Link>
          </p>
          <p className="mb-3 text-white">
            <a
              className="text-white"
              href="https://energysense.guampowerauthority.com"
            >
              <u>View Approved Model Listing</u>
            </a>
          </p>
          <p className="pd-6">
            <a
              href="https://guampowerauthority.com/energysense"
              className="text-white"
            >
              <u>Online Rebate Requirements and FAQ</u>
            </a>
          </p>
          <Row className="p-4" />
        </Row>
        <Row>
          <br />
          <small className="text-secondary position-absolute footer-text">
            Energy Sense Rebate Program <br />
            Copyright &copy; 2023 GPA Powered By Xtendly
          </small>
        </Row>
      </Col>
    </Row>
  );
}

export default HomeScreen;
