export const APPLICATION_ADD_REQUEST = "APPLICATION_ADD_REQUEST";
export const APPLICATION_ADD_SUCCESS = "APPLICATION_ADD_SUCCESS";
export const APPLICATION_ADD_FAIL = "APPLICATION_ADD_FAIL";

export const APPLICATION_LIST_REQUEST = "APPLICATION_LIST_REQUEST";
export const APPLICATION_LIST_SUCCESS = "APPLICATION_LIST_SUCCESS";
export const APPLICATION_LIST_FAIL = "APPLICATION_LIST_FAIL";
export const APPLICATION_LIST_RESET = "APPLICATION_LIST_RESET";

export const APPLICATION_LIST_RECORD_REQUEST =
  "APPLICATION_LIST_RECORD_REQUEST";
export const APPLICATION_LIST_RECORD_SUCCESS =
  "APPLICATION_LIST_RECORD_SUCCESS";
export const APPLICATION_LIST_RECORD_FAIL = "APPLICATION_LIST_RECORD_FAIL";
export const APPLICATION_LIST_RECORD_RESET = "APPLICATION_LIST_RECORD_RESET";

export const APPLICATION_DELETE_REQUEST = "APPLICATION_DELETE_REQUEST";
export const APPLICATION_DELETE_SUCCESS = "APPLICATION_DELETE_SUCCESS";
export const APPLICATION_DELETE_FAIL = "APPLICATION_DELETE_FAIL";

export const APPLICATION_UPDATE_REQUEST = "APPLICATION_UPDATE_REQUEST";
export const APPLICATION_UPDATE_SUCCESS = "APPLICATION_UPDATE_SUCCESS";
export const APPLICATION_UPDATE_FAIL = "APPLICATION_UPDATE_FAIL";

export const APPLICATION_EDIT_REQUEST = "APPLICATION_EDIT_REQUEST";
export const APPLICATION_EDIT_SUCCESS = "APPLICATION_EDIT_SUCCESS";
export const APPLICATION_EDIT_FAIL = "APPLICATION_EDIT_FAIL";

export const APPLICATION_EQUIP_EDIT_REQUEST = "APPLICATION_EQUIP_EDIT_REQUEST";
export const APPLICATION_EQUIP_EDIT_SUCCESS = "APPLICATION_EQUIP_EDIT_SUCCESS";
export const APPLICATION_EQUIP_EDIT_FAIL = "APPLICATION_EQUIP_EDIT_FAIL";

export const APPLICATION_EQUIP_DELETE_REQUEST = "APPLICATION_EQUIP_DELETE_REQUEST";
export const APPLICATION_EQUIP_DELETE_SUCCESS = "APPLICATION_EQUIP_DELETE_SUCCESS";
export const APPLICATION_EQUIP_DELETE_FAIL = "APPLICATION_EQUIP_DELETE_FAIL";

export const BATCH_APPLICATION_UPDATE_REQUEST =
  "BATCH_APPLICATION_UPDATE_REQUEST";
export const BATCH_APPLICATION_UPDATE_SUCCESS =
  "BATCH_APPLICATION_UPDATE_SUCCESS";
export const BATCH_APPLICATION_UPDATE_FAIL = "BATCH_APPLICATION_UPDATE_FAIL";

export const APPLICATION_DETAIL_REQUEST = "APPLICATION_DETAIL_REQUEST";
export const APPLICATION_DETAIL_SUCCESS = "APPLICATION_DETAIL_SUCCESS";
export const APPLICATION_DETAIL_FAIL = "APPLICATION_DETAIL_FAIL";
export const APPLICATION_DETAIL_RESET = "APPLICATION_DETAIL_RESET";

export const APPLICATION_DETAIL_PRINT_REQUEST =
  "APPLICATION_DETAIL_PRINT_REQUEST";
export const APPLICATION_DETAIL_PRINT_SUCCESS =
  "APPLICATION_DETAIL_PRINT_SUCCESS";
export const APPLICATION_DETAIL_PRINT_FAIL = "APPLICATION_DETAIL_PRINT_FAIL";
export const APPLICATION_DETAIL_PRINT_RESET = "APPLICATION_DETAIL_PRINT_RESET";

export const APPLICATION_COMMENTS_REQUEST = "APPLICATION_COMMENTS_REQUEST";
export const APPLICATION_COMMENTS_SUCCESS = "APPLICATION_COMMENTS_SUCCESS";
export const APPLICATION_COMMENTS_FAIL = "APPLICATION_COMMENTS_FAIL";
export const APPLICATION_COMMENTS_RESET = "APPLICATION_COMMENTS_RESET";

export const COMMENT_ADD_REQUEST = "COMMENT_ADD_REQUEST";
export const COMMENT_ADD_SUCCESS = "COMMENT_ADD_SUCCESS";
export const COMMENT_ADD_FAIL = "COMMENT_ADD_FAIL";

export const APPLICATION_LOGS_REQUEST = "APPLICATION_LOGS_REQUEST";
export const APPLICATION_LOGS_SUCCESS = "APPLICATION_LOGS_SUCCESS";
export const APPLICATION_LOGS_FAIL = "APPLICATION_LOGS_FAIL";
export const APPLICATION_LOGS_RESET = "APPLICATION_LOGS_RESET";

export const APPLICATION_TRACK_REQUEST = "APPLICATION_TRACK_REQUEST";
export const APPLICATION_TRACK_SUCCESS = "APPLICATION_TRACK_SUCCESS";
export const APPLICATION_TRACK_FAIL = "APPLICATION_TRACK_FAIL";
