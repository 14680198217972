import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  ListGroup,
  Modal,
  Tab,
  Badge,
  InputGroup,
  FormControl,
  Container,
  Button,
  Nav,
} from "react-bootstrap";
import StringCrypto from "string-crypto";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { useWindowDimensions } from "../../hooks";
import { formatAMPM } from "../../helpers";
import city_zipcode from "./city_zipcode";

import {
  detailApplication,
  commentsApplication,
  addCommentAction,
  logsApplication,
  updateApplication,
} from "../../actions/applicationActions";
import { retrieveFileAction } from "../../actions/fileActions";
import { uploadFileAction, updateFileAction } from "../../actions/fileActions";
import {
  editApplication,
  editEquipment,
} from "../../actions/applicationActions";
import {
  loadCustomerSystemType,
  loadCustomerEquipManufacturer,
  loadCustomerEquipModel,
  loadCustomerEquipmentDetail,
} from "../../actions/customerAction";
import ModalImage from "../ModalImage";

import "./ViewApp.css";

const MySwal = withReactContent(Swal);
const date = new Date(Date.now());
date.setDate(date.getDate());
const currentDate = moment(date).format("YYYY-MM-DD");

function ViewAppRecords(props) {
  const Toast = MySwal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  let total_rebate = 0;
  let inv,
    irs,
    loa,
    disp,
    oth1,
    oth2 = "";
  const { encryptString } = new StringCrypto();
  const { height, width } = useWindowDimensions();
  let obj = JSON.parse(localStorage.getItem("userInfo"));
  let roleId = obj.message.original.roleId;

  const [reload, setReload] = useState(0);
  const [paintOne, setPaintOne] = useState(false);
  const [paintTwo, setPaintTwo] = useState(false);
  const [paintThree, setPaintThree] = useState(false);
  const [paintFour, setPaintFour] = useState(false);

  const [tabThree, setTabThree] = useState(true);
  const [tabFour, setTabFour] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editOldModal, setShowEditOldModal] = useState(false);

  const [status, setStatus] = useState("");
  const [stage, setStage] = useState("");
  const [reason, setReason] = useState("");
  const [batch, setBatch] = useState("");
  const [comment, setComment] = useState("");
  const [swalInfo, setSwalInfo] = useState("");
  const [updateState, setUpdateState] = useState(0);
  const [submited, setSubmited] = useState(false);
  const [detailsToggle, setDetailsToggle] = useState(false);

  const [enable_equipment_edit, setEnableEquipmentEdit] = useState(false);
  const [customer_name, setCustomerName] = useState("");
  const [service_location, setServiceLocation] = useState("");
  const [city_village, setCityVillage] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [tel_no, setTelNo] = useState("");
  const [email, setEmail] = useState("");
  const [is_applicant_owner, setIsApplicantOwner] = useState(false);
  const [mailing_address, setMailingAddress] = useState("");
  const [mailing_city_village, setMailingCityVillage] = useState("");
  const [mailing_zipcode, setMailingZipCode] = useState("");
  const [home_size, setHomeSize] = useState("");
  const [home_age, setHomeAge] = useState("");
  const [home_type, setHomeType] = useState("");
  const [is_new_construction, setIsNewConstruction] = useState();

  const [enable_installer_edit, setEnableInstallerEdit] = useState(false);
  const [system_type, setSystemType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [btu, setBtu] = useState("");
  const [seer, setSeer] = useState("");
  const [vendor, setVendor] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [model_no, setModelNo] = useState("");
  const [invoice_no, setInvoiceNo] = useState("");
  const [purchase_date, setPurchaseDate] = useState("");

  const [old_system_type, setOldSystemType] = useState("");
  const [old_years, setOldYears] = useState("");
  const [old_quantity, setOldQuantity] = useState("");
  const [old_tons, setOldTons] = useState("");
  const [old_btu, setOldBtu] = useState("");
  const [old_equipment_condition, setOldEquipmentCondition] = useState("");
  const [old_seer, setOldSeer] = useState("");
  const [old_disposal_party, setOldDisposalParty] = useState("");
  const [old_disposal_date, setOldDisposalDate] = useState("");

  const [installer_name, setInstallerName] = useState("");
  const [work_tel, setWorkTel] = useState("");
  const [company, setCompany] = useState("");
  const [cert_no, setCertNo] = useState("");
  const [installer_email, setInstallerEmail] = useState("");
  const [installer_final_date, setInstallerFinalDate] = useState("");
  const [delay_reason, setDelayReason] = useState("");
  const [delay_reason2, setDelayReason2] = useState("");

  const [invoice, setInvoice] = useState(null);
  const [irs_form, setIrsForm] = useState(null);
  const [disposal_slip, setDisposalSlip] = useState(null);
  const [letter_authorization, setLetterAuthorization] = useState(null);
  const [installer_cert, setInstallerCertification] = useState(null);
  const [other_doc1, setOtherDoc1] = useState(null);
  const [other_doc2, setOtherDoc2] = useState(null);
  const [modelId, setModelID] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [modelName, setModelName] = useState("");
  const [uploadCount, setUploadCount] = useState(0);

  const [installationAddressFix, setInstallationAddressFix] = useState("");
  const [countryFix, setCountryFix] = useState("");
  const [mailingAddressFix, setMailingAddressFix] = useState("");

  const [modalData, setModalData] = useState({
    description: "",
    image_sample: "",
  });

  const [updateInfoReload, setUpdateInfoReload] = useState(0);
  const [selectedEquipmentIndex, setSelectedEquipmentIndex] = useState(0);
  const [selectedOldEquipmentIndex, setSelectedOldEquipmentIndex] = useState(0);

  const [datePurchaseHasReason, setDatePurchaseHasReason] = useState(false);
  const [finalDateHasReason, setFinalDateHasReason] = useState(false);

  const ChangeDate = (e) => {
    setInstallerFinalDate(e.target.value)

    // FOR DATE PURCHASE DELAY REASON
    var hasReason = [];

    if(application?.New_equipment[0] != null){
      application.New_equipment.forEach(element => {
        if(Math.abs(new Date(element.newEquip_Purchase_date) - new Date(e.target.value) ) / (1000 * 3600 * 24) >= 120 ){
          hasReason.push("1")
        }
      });
    }

    if(hasReason.length >= 1){
      setDatePurchaseHasReason(true);
    } else {
      setDatePurchaseHasReason(false);
      setDelayReason(null);
    }

    // FOR FINAL INSTALLATION DELAY REASON
    if(Math.abs(new Date(e.target.value) - new Date(application.Application_Date) ) / (1000 * 3600 * 24) >= 120 ){
      setFinalDateHasReason(true);
    } else {
      setFinalDateHasReason(false);
      setDelayReason2(null)
    }

  }

  const ChangeDateOnModal = (e) => {
    setPurchaseDate(e.target.value);

    // FOR PURCHASE DELAY REASON
    if(Math.abs(new Date(e.target.value) - new Date(application.Installer_New_finaldate) ) / (1000 * 3600 * 24) >= 120 ){
      setDatePurchaseHasReason(true);
    } else {
      setDatePurchaseHasReason(false);
      setDelayReason(null)
    }
  }

  const handleOnChange = (e, doc_type, control_no) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    Toast.fire({
      icon: "info",
      title: "Uploaded Successfully",
      text: "Please wait while the uploaded file is being fetched.",
    });

    dispatch(
      uploadFileAction(e.target.files[0], doc_type, control_no, user.message.original.details.id, true, props.applicationId)
    ).then(() => {
      if (doc_type === "irs_form") {
        setIrsForm(e.target.files[0]);
      } else if (doc_type === "other_doc1") {
        setOtherDoc1(e.target.files[0]);
      } else if (doc_type === "other_doc2") {
        setOtherDoc2(e.target.files[0]);
      } else if (doc_type === "letter_authorization") {
        setLetterAuthorization(e.target.files[0]);
      } else if (doc_type === "invoice") {
        setInvoice(e.target.files[0]);
      } else if (doc_type === "installer_cert") {
        setInstallerCertification(e.target.files[0]);
      } else if (doc_type === "disposal_slip") {
        setDisposalSlip(e.target.files[0]);
      }

      setUploadCount(uploadCount + 1);
    });

    return;
  };
  const handleModalClose = () => {
    setShowModal(false);
    setShowEditModal(false);
    setShowEditOldModal(false);
  };

  const dispatch = useDispatch();

  const updateFile = useSelector((state) => state.updateFile);

  const applicationUpdate = useSelector((state) => state.applicationUpdate);
  const {
    error: updateError,
    loading: updateLoading,
    success: successUpdate,
  } = applicationUpdate;

  const addComment = useSelector((state) => state.addComment);
  const {
    error: commentError,
    loading: commentLoading,
    success: commentSucess,
  } = addComment;

  const retrieveFile = useSelector((state) => state.retrieveFile);
  const {
    error: retrieveError,
    loading: retrieveLoading,
    success: retrieveSuccess,
  } = retrieveFile;

  const batchAdd = useSelector((state) => state.batchAdd);
  const { success: addBatchSuccess } = batchAdd;

  const uploadFile = useSelector((state) => state.uploadFile);
  const { loading: uploadLoading, error: uploadError, fileCode } = uploadFile;

  const applicationEdit = useSelector((state) => state.applicationEdit);
  const { loading: editLoading, error: editError, edit_info } = applicationEdit;

  const equipmentEdit = useSelector((state) => state.equipmentEdit);
  const {
    loading: editEquipmentLoading,
    error: editEquipmentError,
    edit_equip,
  } = equipmentEdit;

  const applicationDetail = useSelector((state) => state.applicationDetail);
  const { loading, error, application } = applicationDetail;

  // const applicationDetail = useSelector((state) => state.applicationDetail);
  const applicationComments = useSelector((state) => state.applicationComments);
  const {
    loading: loadingComments,
    error: errorComments,
    comments,
  } = applicationComments;
  // const [application, setApplication] = useState();
  const applicationLogs = useSelector((state) => state.applicationLogs);
  const { loading: loadingLogs, error: errorLogs, logs } = applicationLogs;

  const customerSystemType = useSelector((state) => state.customerSystemType);
  const {
    loading: systemTypeLoading,
    error: systemTypeError,
    success: systemTypeSuccess,
    system_types,
  } = customerSystemType;

  const customerEquipManufacturer = useSelector(
    (state) => state.customerEquipManufacturer
  );
  const {
    loading: manufacturerLoading,
    error: manufacturerError,
    success: manufacturerSuccess,
    manufacturers,
  } = customerEquipManufacturer;

  const customerEquipModel = useSelector((state) => state.customerEquipModel);
  const {
    loading: modelLoading,
    error: modelError,
    success: modelSuccess,
    models,
  } = customerEquipModel;

  const customerEquipmentDetail = useSelector(
    (state) => state.customerEquipmentDetail
  );
  const {
    loading: equipDetailLoading,
    error: equipDetailError,
    success: equipDetailSuccess,
    equipment_detail,
  } = customerEquipmentDetail;

  useEffect(() => {
    dispatch(detailApplication(props.applicationId));
    dispatch(commentsApplication(props.applicationId));
    dispatch(logsApplication(props.applicationId));
  }, [reload, updateInfoReload, edit_equip, uploadCount]);

  const resetHandler = () => {
    props.setShow(false);
  };

  const reloadHandler = () => {
    Toast.fire({
      icon: "info",
      title: "Loading Data",
      text: "Please wait while the table is loading the data.",
    });
    setReload(reload + 1);

  };

  const handleDetailsToggle = () => {
    if (detailsToggle === false) {
      setDetailsToggle(true);
    } else {
      setDetailsToggle(false);
    }
  };

  const addCommentHandler = () => {
    dispatch(addCommentAction(props.applicationId, comment));

    const Toast = MySwal.mixin({
      toast: true,
      position: "bottom-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3500,
      timerProgressBars: true,
    });

    Toast.fire({
      icon: "success",
      title: "Comment Sent",
      text: "",
    });

    setComment("");
    setReload(reload + 1);
  };

  const changeCommentHandler = (text) => {
    setComment(text);
  };

  const handleRetrieveFile = (code, filename) => {
    dispatch(retrieveFileAction(code, filename, props.applicationId));
  };

  const handleEditInfo = () => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    const obj = {
      applicationId: props.applicationId,
      customerName: customer_name
        ? customer_name
        : application.Info_Customer_name,
      serviceLocation: service_location
        ? service_location
        : application.Info_Service_location,
      cityVillage: city_village ? city_village : application.Info_City_village,
      zipcode: zipcode ? zipcode : application.Info_Zipcode,
      email: email ? email : application.Info_Email,
      telNo: tel_no ? tel_no : application.Info_Tel_no,
      isApplicantOwner: is_applicant_owner
        ? is_applicant_owner
        : application.Info_Is_owner,
      mailingAddress: mailingAddressFix
        ? mailingAddressFix+"^"+countryFix
        : application.Info_Mailing_address,
      mailingCity: mailing_city_village
        ? mailing_city_village
        : application.Info_Mailing_city,
      mailingZipcode: mailing_zipcode
        ? mailing_zipcode
        : application.Info_Mailing_zip,
      homeSize: home_size
        ? home_size
        : application.Info_Home_size,
      homeAge: home_age ? home_age : application.Info_Home_age,
      homeType: home_type ? home_type : application.Info_Home_type,
      isNewConstruction: is_new_construction
        ? is_new_construction
        : application.Info_New_construction,
      Delay_Reason: application.Delay_Reason,
      Delay_Reason2: application.Delay_Reason,
      totalrebate: total_rebate,
      UserId: user.message.original.details.id
    };
    dispatch(editApplication(obj));
    Swal.fire("Success", "Application has been updated!", "success");
    setUpdateInfoReload(updateInfoReload + 1);
  };

  const handleEditDelay = (reasonFinalDate, reasonPurchaseDate) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    const obj = {
      applicationId: props.applicationId,
      customerName: customer_name
        ? customer_name
        : application.Info_Customer_name,
      serviceLocation: service_location
        ? service_location
        : application.Info_Service_location,
      cityVillage: city_village ? city_village : application.Info_City_village,
      zipcode: zipcode ? zipcode : application.Info_Zipcode,
      email: email ? email : application.Info_Email,
      telNo: tel_no ? tel_no : application.Info_Tel_no,
      isApplicantOwner: is_applicant_owner
        ? is_applicant_owner
        : application.Info_Is_owner,
      mailingAddress: mailingAddressFix
        ? mailingAddressFix+"^"+countryFix
        : application.Info_Mailing_address,
      mailingCity: mailing_city_village
        ? mailing_city_village
        : application.Info_Mailing_city,
      mailingZipcode: mailing_zipcode
        ? mailing_zipcode
        : application.Info_Mailing_zip,
      homeSize: home_size
        ? home_size
        : application.Info_Home_size,
      homeAge: home_age ? home_age : application.Info_Home_age,
      homeType: home_type ? home_type : application.Info_Home_type,
      isNewConstruction: is_new_construction
        ? is_new_construction
        : application.Info_New_construction,
      Delay_Reason: datePurchaseHasReason ? (delay_reason ? delay_reason : application.Delay_Reason) : null,
      Delay_Reason2: finalDateHasReason ? (delay_reason2 ? delay_reason2 : application.Delay_Reason2) : null,
      totalrebate: total_rebate,
      UserId: user.message.original.details.id
    };
    dispatch(editApplication(obj));
    Swal.fire("Success", "Application has been updated!", "success");
    setUpdateInfoReload(updateInfoReload + 1);
  }

  const changeZipCode = (e) => {
    setCityVillage(e.target.value);
    const result = city_zipcode.find((p) => p._id === e.target.value);

    if (result) {
      setZipCode(result.zip_code);
    }
  };

  const changeSystemTypeHandler = (e) => {
    showRebateHandler();
    setVendor("");
    setSystemType(e);
    systemTypeVal = e;
    dispatch(loadCustomerEquipManufacturer(e));
  };

  const showRebateHandler = () => {
    if (system_type !== "Dryer" || system_type !== "Washer") {
      return <></>;
    } else {
      return <></>;
    }
  };

  const changeManufacturerHandler = (system_type, e) => {
    setManufacturer(e);
    dispatch(loadCustomerEquipModel(system_type, e));
  };
  const handleModelNo = (id) => {
    var modelName = "";
    // me.indoor_model
    //   ? me.indoor_model
    //   : "" + me.outdoor_model && me.indoor_model
    //   ? " / "
    //   : " " + me.outdoor_model
    //   ? me.outdoor_model
    //   : "";

    if (id?.package_model) {
      modelName = id.package_model;
      return modelName;
    } 
    
    if (
      id?.indoor_model && (id?.indoor_model !== "-" ||
      id?.indoor_model !== " ")
    ) {
      modelName = id.indoor_model;
    } 
    
    if (id?.outdoor_model) {
      modelName = id.outdoor_model;
    } 
    
    if (id?.indoor_model && id?.outdoor_model) {
      modelName = id.indoor_model + " / " + id.outdoor_model;
    }

    return modelName;

    // if (id) {
    //   switch (id.model) {
    //     case "Indoor / Outdoor": {
    //       return id.indoor_model + " / " + id.outdoor_model;
    //     }
    //     case "Package": {
    //       return id.package_model;
    //     }
    //     case "Both": {
    //       return id.indoor_model + " / " + id.outdoor_model + " / " + id.model;
    //     }
    //     default: {
    //       return id.model;
    //     }
    //   }
    // }
  };
  const changeModelHandler = (e, customer_type) => {
    setModelID(e);
    var selectedModel = models.find((model) => model.id === parseInt(e));

    var selectedModelName = handleModelNo(selectedModel);
    setModelNumber(e);
    setModelName(selectedModelName);
    dispatch(loadCustomerEquipmentDetail(e, customer_type));
  };

  const handleEquipmentEdit = (
    index,
    equipment_id,
    system_type,
    manifacturer,
    model_no,
    type
  ) => {

    dispatch(loadCustomerSystemType(type));

    setSelectedEquipmentIndex(index);
    setShowEditModal(true);

    changeSystemTypeHandler(system_type);
    changeManufacturerHandler(system_type, manifacturer);
    changeModelHandler(model_no, type);
    setEnableEquipmentEdit(true);
  };

  const handleEditOldEquipment = (equipment_id, indx) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    const obj = {
      existing_old_equipment_information: [
        {
          id: equipment_id,
          system_type: old_system_type
            ? old_system_type
            : application.Old_equipment[indx].oldEquip_System_type,
          btu: old_btu ? old_btu : application.Old_equipment[indx].oldEquip_Btu,
          size: 0,
          years: old_years
            ? old_years
            : application.Old_equipment[indx].oldEquip_Years,
          quantity: old_quantity
            ? old_quantity
            : application.Old_equipment[indx].oldEquip_Quantity,
          tons: old_tons
            ? old_tons
            : application.Old_equipment[indx].oldEquip_Tons,
          is_equipment_condition: old_equipment_condition
            ? old_equipment_condition
            : application.Old_equipment[indx].oldEquip_Conditon,
          seer: old_seer
            ? old_seer
            : application.Old_equipment[indx].oldEquip_Seer,
          disposal_party: old_disposal_party
            ? old_disposal_party
            : application.Old_equipment[indx].oldEquip_Disposal_party,
          date: old_disposal_date
            ? old_disposal_date
            : application.Old_equipment[indx].oldEquip_Disposal_date,
          UserId: user.message.original.details.id
        },
      ],
    };
    dispatch(editEquipment(obj, props.applicationId));
    Swal.fire("Success", "Installer's Info has been updated!", "success");
    setShowEditOldModal(false);
    setReload(reload + 1);
  };

  const editInstallerHandler = () => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    const obj = {
      installer_information: {
        id: application.Installer_New_id,
        technician_name: installer_name
          ? installer_name
          : application.Installer_New_name,
        work_tel: work_tel ? work_tel : application.Installer_New_worktel,
        company_name: company ? company : application.Installer_New_companyname,
        technician_cert_no: cert_no
          ? cert_no
          : application.Installer_New_certno,
        date_final_installation: installer_final_date
          ? installer_final_date
          : application.Installer_New_finaldate,
        email: installer_email
          ? installer_email
          : application.Installer_New_email,
          applicationId: props.applicationId,
          UserId: user.message.original.details.id
      },
    };
    
    dispatch(editEquipment(obj));
    handleEditDelay();
    setEnableInstallerEdit(false);
    setReload(reload + 1);

  };

  const handleEditNewEquipment = (control_no, equipment_id, indx) => {
    let user = JSON.parse(localStorage.getItem("userInfo"));

    // to avoid confusions take note that TONS values are saved as BTU in the backend
    const obj = {
      new_equipment_information: [
        {
          id: equipment_id,
          system_type: system_type
            ? system_type
            : application.New_equipment[indx].newEquip_System_type,
          vendor: vendor
            ? vendor
            : application.New_equipment[indx].newEquip_Vendor,
          // quantity:quantity ? quantity : application.New_equipment[indx],
          btu: equipment_detail ? equipment_detail[0]?.tons : 0,
          // size:size ? size : application.New_equipment[indx] ,
          manufacturer: manufacturer
            ? manufacturer
            : application.New_equipment[indx].newEquip_Manufacturer,
          model_no: modelName
            ? modelName
            : application.New_equipment[indx].newEquip_Model_no,
          invoice_no: invoice_no
            ? invoice_no
            : application.New_equipment[indx].newEquip_Invoice_no,
          purchase_date: purchase_date
            ? purchase_date
            : application.New_equipment[indx].newEquip_Purchase_date,
          quantity: quantity
            ? quantity
            : application.New_equipment[indx].newEquip_Quantity,
          size: 0,
          type: "",
          tons: "",
          seer: equipment_detail ? equipment_detail[0]?.value1 : 0,
          rebate: equipment_detail ? equipment_detail[0]?.rebate : 0,
          UserId: user.message.original.details.id,
          applicationId: props.applicationId
        },
      ],
      // installer_information: {
      //   id: application.Installer_New_id,
      //   technician_name: installer_name
      //     ? installer_name
      //     : application.Installer_New_name,
      //   work_tel: work_tel ? work_tel : application.Installer_New_worktel,
      //   company_name: company ? company : application.Installer_New_companyname,
      //   technician_cert_no: cert_no
      //     ? cert_no
      //     : application.Installer_New_certno,
      //   date_final_installation: installer_final_date
      //     ? installer_final_date
      //     : application.Installer_New_finaldate,
      //   email: installer_email
      //     ? installer_email
      //     : application.Installer_New_email,
      // },
    };

    if (
      equipment_id === "" ||
      system_type === "" ||
      vendor === "" ||
      manufacturer === "" ||
      modelName === "" ||
      invoice_no === "" ||
      purchase_date === "" ||
      quantity === ""
    ) {
      Swal.fire("Edit Failed", "Please Fill out the required Forms", "error");
    } else {
      

      total_rebate = 0;
      const equipment = application.New_equipment.find(
        equipment => equipment.newEquip_id === equipment_id
      );

      equipment.newEquip_rebate = equipment_detail ? equipment_detail[0]?.rebate : 0;

      application.New_equipment.map((eq) => {
        total_rebate += eq.newEquip_rebate;
      });
      
      const obj2 = {
        applicationId: props.applicationId,
        totalrebate: total_rebate,
      };
      dispatch(editEquipment(obj, props.applicationId));
      handleEditDelay();
      setShowEditModal(false);
      setReload(reload + 1);
    }
  };

  const changeStatusHandler = (status) => {
    setSubmited(false);
    setStatus(status);
    setShowModal(true);
  };

  const editOldEquipmentHandler = (indx, id, type) => {
    setSelectedOldEquipmentIndex(indx);
    setShowEditOldModal(true);
    dispatch(loadCustomerSystemType(type));
  };

  const updateStatus = (status, stage, desc) => {
    setStage(stage);
    setSubmited(true);
    if (status !== 3 || status !== "" || stage !== "") {
      setStatus(status);
      setStage(stage);

      Swal.fire({
        title: `Are you sure you want to ${desc}?`,
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        // denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            updateApplication(props.applicationId, status, stage, reason, batch)
          );
          props.setShow(false);
          setSubmited(false);
          Swal.fire("Success", "Application has been processed!", "success");
          setShowModal(false);
          props.setApplicationId(0);
          props.setReload(props.reload + 1);
        }
      });
    } else if (status === 3) {
      Swal.fire({
        title: "Are you sure you want to reject application?",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        // denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            updateApplication(props.applicationId, status, stage, reason)
          );
          props.setShow(false);
          setSubmited(false);
          Swal.fire("Success", "Application has been rejected!", "success");
          setShowModal(false);
        }
      });
    }
  };

  let seerVal = 0;
  let systemTypeVal = "";

  const errorFileInvalidMessage = () => {
    const Toast = MySwal.mixin({
      toast: true,
      position: "top-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3500,
      timerProgressBars: true,
    });

    Toast.fire({
      icon: "info",
      title: "Invalid File Uploaded",
      text: "Please note that only Images (JPG, JPEG, PNG) and PDF files are accepted by the system.",
    });
  };

  useEffect(() => {
    if(application?.Info_Mailing_address != undefined){
      const words = application.Info_Mailing_address.split("^")
      setMailingAddressFix(words[0])
      setCountryFix(words[1] ? words[1] : "N/A")
    }

    if(application?.New_equipment && application?.Installer_New_finaldate && application?.Application_Date){
      // APPLICATION ONLOAD
      
      // FOR PURCHASE DATE
      if(application?.New_equipment[0] != null){
        application.New_equipment.forEach(element => {
          if(Math.abs(new Date(element.newEquip_Purchase_date) - new Date(application?.Installer_New_finaldate) ) / (1000 * 3600 * 24) >= 120 ){
            setDatePurchaseHasReason(true);
          }
        });
      }

      // FOR INSTALLER FINAL DATE
      if(Math.abs(new Date(application?.Application_Date) - new Date(application?.Installer_New_finaldate) ) / (1000 * 3600 * 24) >= 120 ){
        setFinalDateHasReason(true);
      }
    }
  }, [application]);
  
  return (
    <div>
      <Container>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="application_information"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button className="mb-3 btn btn-light" onClick={() => resetHandler()}>
              <i className="fa fa-arrow-left"></i> Back to Application
            </Button>
            <Button
              className="mb-3 btn btn-success"
              onClick={() => reloadHandler()}
            >
              <i className="fa fa-refresh"></i> Reload Application
            </Button>
            <h4 style={{ marginLeft: "auto" }}>{application?.Control_Number}</h4>
          </div>
          
          {/* navigation tabs */}
          <Row style={{ paddingLeft: 12 }}>
            <Col
              className="p-0"
              style={{ backgroundColor: "rgb(227, 227, 229)" }}
            >
              <div id="applicationFormNa">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="application_information"
                      className={paintOne ? "bg-info text-white" : ""}
                      onClick={() => {
                        setPaintOne(true);
                      }}
                    >
                      Applicant Information
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      className={paintTwo ? "bg-info text-white" : ""}
                      eventKey="new_quipment_info"
                      onClick={() => {
                        setTabThree(false);
                        setPaintTwo(true);
                        setPaintOne(true);
                      }}
                    >
                      New Equipment Information
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="old_quipment_info"
                      disabled={tabThree}
                      className={paintThree ? "bg-info text-white" : ""}
                      onClick={() => {
                        setTabFour(false);
                        setPaintThree(true);
                      }}
                    >
                      Old/Existing Equipment Information
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="submission_of_documentation"
                      disabled={tabFour}
                      className={paintFour ? "bg-info text-white" : ""}
                      onClick={() => {
                        setPaintFour(true);
                      }}
                    >
                      Submitted Documents
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    style={{ marginLeft: "auto", width: 50, paddingTop: 10 }}
                    className="d-flex aligns-items-center justify-content-center editbtn"
                    onClick={() => handleDetailsToggle()}
                  >
                    <i className="fa fa-edit"></i>
                  </Nav.Item>
                  <Nav.Item
                    style={{ width: 50, paddingTop: 10 }}
                    className="d-flex aligns-items-center justify-content-center editbtn"
                  >
                    <Link
                      className="text-black"
                      to={`/printapplication?auth=${encryptString(
                        application ? application.Control_Number : "",
                        "superSecureToken"
                      )}`}
                      target="_blank"
                    >
                      <i className="fa fa-print"></i>
                    </Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={detailsToggle ? 9 : 12}>
              <Tab.Content>
                {/* NOTE: APPLICATION TAB */}
                <Tab.Pane eventKey="application_information">
                  <h3 className="mt-3 mb-5 text-info">Applicant Info</h3>
                  {application ? (
                    <>
                    <div style={{ marginRight: 50, maxWidth: 770}}>
                        <Row>
                            <Col>
                                <p>
                                    <b style={{ color: "#B6B6B6" }}>Control Number</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                <b>{application.Control_Number || "N/A"} </b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    <b style={{ color: "#B6B6B6" }}>GPA Electric Account Number</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                <b>{application.Info_Account_no || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>Bill ID</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                <b>{application.Info_Bill_id || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    <b style={{ color: "#B6B6B6" }}>GPA Account Holder's Name</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Account_Name || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15}}>
                            <Col>
                                <p>
                                    <b style={{ color: "#B6B6B6" }}>Applicant's Name</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Customer_name || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>
                                    Installation Address
                                </b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Service_location || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>City</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>
                                        {application.Info_City_village
                                        ? city_zipcode.find(
                                            (p) =>
                                                p._id ===
                                                application.Info_City_village
                                            )
                                            ? city_zipcode.find(
                                                (p) =>
                                                p._id ===
                                                application.Info_City_village
                                            ).village
                                            : "N/A"
                                        : "N/A" || "N/A"}
                                    </b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>Zip</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Zipcode || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>Email</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Email || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>Telephone Number</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Tel_no || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    <b style={{ color: "#B6B6B6" }}>
                                        Is Applicant the owner of the <br />
                                        property?
                                    </b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>
                                        {application.Info_Is_owner == 1
                                        ? "YES"
                                        : "NO" || "N/A"}
                                    </b>
                                </p>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15}}>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>MAILING ADDRESS</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{mailingAddressFix || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>CITY</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Mailing_city || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>COUNTRY</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{countryFix || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>ZIP</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Mailing_zip || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>HOME SIZE (approx. sq. ft.)</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Home_size || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>
                                    {application
                                    ? application.Type === "RESID"
                                        ? "HOME"
                                        : "BUILDING"
                                    : ""}{" "}
                                    AGE
                                </b>
                            </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Home_age || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>NEW CONSTRUCTION</b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>
                                        {application.Info_New_construction == "true"
                                        ? "YES"
                                        : "NO" || "N/A"}
                                    </b>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                <b style={{ color: "#B6B6B6" }}>
                                    {application
                                    ? application.Type === "RESID"
                                        ? "HOME"
                                        : "BUILDING"
                                    : ""}{" "}
                                    TYPE
                                </b>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <b>{application.Info_Home_type || "N/A"}</b>
                                </p>
                            </Col>
                        </Row>
                    </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Tab.Pane>

                {/* NOTE: NEW EQUIPMENT TAB */}
                <Tab.Pane eventKey="new_quipment_info">
                  <h3 className="mt-3 mb-3 text-info">New Equipment Info</h3>


                  <Row className="px-0">
                    <Col className="mb-2" md={12}>
                      <Table striped hover>
                        <thead className="bg-info text-white">
                          <tr>
                            <th>#</th>
                            <th>System Type</th>

                            <th>Vendor</th>
                            <th>Manu facturer</th>
                            <th>Model Number</th>
                            {application ? (
                              application.New_equipment[0]
                                .newEquip_System_type === "Dryer" ||
                              application.New_equipment[0]
                                .newEquip_System_type === "Washer" ? (
                                <th>Cubic Feet</th>
                              ) : (
                                <>
                                  <th>BTU</th>
                                  <th>Tons</th>
                                  <th>Seer</th>
                                </>
                              )
                            ) : null}
                            <th>Invoice</th>
                            <th>Purchase Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {application
                            ? application?.New_equipment?.length === 0
                              ? []
                              : application.New_equipment.map((equip, indx) => (
                                  <tr>
                                    <td>{indx + 1}</td>
                                    <td>{equip.newEquip_System_type}</td>

                                    <td>{equip.newEquip_Vendor} </td>
                                    <td>{equip.newEquip_Manufacturer}</td>
                                    <td>{equip.newEquip_Model_no}</td>
                                    {equip.newEquip_System_type == "Washer" ||
                                    equip.newEquip_System_type == "Dryer" ? (
                                      <td>{equip.newEquip_Seer}</td>
                                    ) : (
                                      <>
                                        <td>
                                          {parseInt(
                                            parseFloat(equip.newEquip_Btu) * 12000
                                          )}
                                        </td>
                                        <td>{equip.newEquip_Btu}</td>
                                        <td>{equip.newEquip_Seer}</td>
                                      </>
                                    )}
                                    <td>{equip.newEquip_Invoice_no}</td>
                                    {/* <td>{equip.newEquip_Tons}</td> */}
                                    <td>{equip.newEquip_Purchase_date}</td>
                                  </tr>
                                ))
                            : []}
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={6}>
                      {application ? (
                        application?.New_equipment?.length >= 1 ? (
                          <>
                            <h3 className="mt-3 mb-3 text-info">
                              Installer Information
                            </h3>
                            <div style={{ marginRight: 50}}>
                              <Row>
                                <Col>
                                  <p>
                                    <b style={{ color: "#B6B6B6" }}>
                                      Technician Name
                                    </b>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                        <b>
                                            {application.Installer_New_name || "N/A"}
                                        </b>
                                    </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p classname={enable_installer_edit ? "mt-1" : null}>
                                    <b style={{ color: "#B6B6B6" }}>
                                      Work Telephone 
                                    </b>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                      <b>
                                        {application.Installer_New_worktel || "N/A"}
                                      </b>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p>
                                    <b style={{ color: "#B6B6B6" }}>Company</b>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                      <b>
                                        {application.Installer_New_companyname ||
                                          "N/A"}
                                      </b>
                                  </p>
                                </Col>
                              </Row>
                              {application.New_equipment[0]
                                .newEquip_System_type === "Washer" ||
                                application.New_equipment[0]
                                .newEquip_System_type === "Dryer" ||
                                application.New_equipment[0]
                                .newEquip_System_type ===
                                "Airconditioner-Window" ? null : (
                                <Row>
                                  <Col>
                                    <p>
                                      <b style={{ color: "#B6B6B6" }}>
                                        Certification No.
                                      </b>
                                    </p>
                                  </Col>
                                  <Col>
                                    <p>
                                        <b>
                                        {application.Installer_New_certno ||
                                            "N/A"}
                                        </b>
                                      </p>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col>
                                  <p>
                                    <b style={{ color: "#B6B6B6" }}>Email</b>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                      <b>
                                        {application.Installer_New_email || "N/A"}
                                      </b>
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p>
                                    <b style={{ color: "#B6B6B6" }}>
                                      Date of Final Installation
                                    </b>
                                  </p>
                                </Col>
                                <Col>
                                  <p>
                                      <b>
                                        {application.Installer_New_finaldate ||
                                          "N/A"}
                                      </b>
                                  </p>
                                </Col>
                              </Row>
                              {datePurchaseHasReason == true ? (
                                  <Row>
                                    <Col>
                                      <p>
                                        <b style={{ color: "#B6B6B6" }}>
                                          Reason For Delay (Date of Purchase)
                                        </b>
                                      </p>
                                    </Col>
                                    <Col>
                                      <p>
                                          <b>{application?.Delay_Reason || "N/A"}</b>
                                      </p>
                                    </Col>
                                  </Row>
                                ) : null}
                                {finalDateHasReason == true ? (
                                  <Row>
                                    <Col>
                                      <p>
                                        <b style={{ color: "#B6B6B6" }}>
                                          Reason For Delay (Final Installation)
                                        </b>
                                      </p>
                                    </Col>
                                    <Col>
                                      <p>
                                          <b>{application?.Delay_Reason2 || "N/A"}</b>
                                      </p>
                                    </Col>
                                  </Row>
                                  
                                ) : null}
                            </div>
                          </>
                        ) : (
                          <>No Equipment</>
                        )
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col md={6} className="mt-3">
                      <Table
                        size="lg"
                        striped
                        bordered
                        hover
                        className="this-table"
                      >
                        <thead className="bg-info text-white">
                          <tr className="py-5">
                            <th className="p-3 text-center">QTY</th>
                            <th className="p-3 text-center">Rebate</th>
                            <th className="p-3 text-center">Partial Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {application?.New_equipment?.map((eq, id) => (
                            <tr key={eq.id + 1}>
                              <td className="p-3" align="right">
                                {eq.newEquip_Quantity}
                              </td>
                              <td className="p-3" align="right">
                                $ {!eq.newEquip_rebate ? 0 : eq.newEquip_rebate}
                              </td>
                              <td className="p-3" align="right">
                                ${" "}
                                {!eq.newEquip_rebate
                                  ? 0
                                  : parseInt(eq.newEquip_Quantity) *
                                    parseInt(eq.newEquip_rebate)}
                              </td>
                              <td hidden>
                                {
                                  (total_rebate +=
                                    parseInt(eq.newEquip_Quantity) *
                                    parseInt(eq.newEquip_rebate))
                                }
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td className="p-3 text-center" colSpan="2">
                              TOTAL
                            </td>
                            <td className="p-3" align="right">
                              $ {application?.totalRebate ? application.totalRebate : "0.00"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Tab.Pane>

                {/* NOTE: OLD EQUIPMENT TAB */}
                <Tab.Pane eventKey="old_quipment_info">
                  <h3 className="mt-3 mb-3 text-info">
                    Existing/Old Equipment Info{" "}
                  </h3>

                  <Table striped hover responsive>
                    <thead className="bg-info text-white">
                      <tr>
                        <th>#</th>
                        <th>System Type</th>
                        <th>Years</th>
                        <th>Quantity</th>
                        {application ? (
                          application.New_equipment[0].newEquip_System_type !==
                          "Washer" ? (
                            application.New_equipment[0].newEquip_System_type !==
                            "Dryer" ? (
                              <th>Tons</th>
                            ) : (
                              <th>Cubic Feet</th>
                            )
                          ) : (
                            <th>Cubic Feet</th>
                          )
                        ) : null}
                        <th>Eqpmt. Condition</th>
                        {application ? (
                          application.New_equipment[0].newEquip_System_type !==
                          "Washer" ? (
                            application.New_equipment[0].newEquip_System_type !==
                            "Dryer" ? (
                              <th>Seer</th>
                            ) : null
                          ) : null
                        ) : null}
                        <th>Disposal Party</th>
                        <th>Disposal Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {application
                        ? application?.Old_equipment?.length === 0
                          ? []
                          : application.Old_equipment.map((old_eqiup, indx) => (
                              <tr>
                                <td>{indx + 1}</td>
                                <td>{old_eqiup.oldEquip_System_type}</td>
                                <td>{old_eqiup.oldEquip_Years}</td>
                                <td>{old_eqiup.oldEquip_Quantity}</td>
                                <td>{old_eqiup.oldEquip_Tons}</td>
                                <td>{old_eqiup.oldEquip_Conditon}</td>
                                {application ? (
                                  application.New_equipment[0]
                                    .newEquip_System_type !== "Washer" ? (
                                    application.New_equipment[0]
                                      .newEquip_System_type !== "Dryer" ? (
                                      <td>{old_eqiup.oldEquip_Seer}</td>
                                    ) : null
                                  ) : null
                                ) : null}

                                <td>{old_eqiup.oldEquip_Disposal_party}</td>
                                <td>{old_eqiup.oldEquip_Disposal_date}</td>
                              </tr>
                            ))
                        : []}
                    </tbody>
                  </Table>
                </Tab.Pane>

                {/* NOTE: SUBMITTED DOCUMENTS */}
                <Tab.Pane eventKey="submission_of_documentation">
                  <ModalImage
                    data={modalData}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <Container className="ml-2 mr-2">
                    <h3 className="mt-3 mb-3 text-info">Submitted Documents</h3>

                    <ListGroup className="mb-3">
                      {application ? (
                        <>
                          <Row>
                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span stlye={{ width: "100%" }}>
                                  Invoice <br />
                                </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0].invoice !==
                                  null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          inv
                                            ? inv
                                            : application.Submitted_docs[0]
                                                .invoice,
                                          application.Control_Number +
                                            "-" +
                                            "Invoice"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span>IRS-W9 </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0].irs_form !==
                                  null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          irs
                                            ? irs
                                            : application.Submitted_docs[0]
                                                .irs_form,
                                          application.Control_Number +
                                            "-" +
                                            "IRS-W9"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    <small className="text-danger">
                                      (*No file uploaded)
                                    </small>
                                  )
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span>Letter of Authorization </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0]
                                    .letter_authorization !== null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          loa
                                            ? loa
                                            : application.Submitted_docs[0]
                                                .letter_authorization,
                                          application.Control_Number +
                                            "-" +
                                            "Letter Authorization"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download{" "}
                                    </Button>
                                  ) : (
                                    <small className="text-danger">
                                      {" "}
                                      (*No file uploaded)
                                    </small>
                                  )
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span>Disposal Slip </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0].disposal_slip !==
                                  null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          disp
                                            ? disp
                                            : application.Submitted_docs[0]
                                                .disposal_slip,
                                          application.Control_Number +
                                            "-" +
                                            "Disposal Slip"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    <small className="text-danger">
                                      (*No file uploaded )
                                    </small>
                                  )
                                ) : null}
                              </div>
                            </Col>

                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span>Other Document 1 </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0].installer_cert !==
                                  null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          oth1
                                            ? oth1
                                            : application.Submitted_docs[0]
                                                .installer_cert,
                                          application.Control_Number +
                                            "-" +
                                            "Other Document1"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    <small className="text-danger">
                                      (*No file uploaded)
                                    </small>
                                  )
                                ) : null}
                              </div>
                            </Col>

                            <Col md={4}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <span>Other Document 2 </span>
                                {application.Submitted_docs ? (
                                  application.Submitted_docs[0].other_doc2 !==
                                  null ? (
                                    <Button
                                      className="mb-2"
                                      variant={"success"}
                                      onClick={() =>
                                        handleRetrieveFile(
                                          oth2
                                            ? oth2
                                            : application.Submitted_docs[0]
                                                .other_doc2,
                                          application.Control_Number +
                                            "-" +
                                            "Other Document"
                                        )
                                      }
                                      size={"sm"}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      Download
                                    </Button>
                                  ) : (
                                    <small className="text-danger">
                                      (*No file uploaded)
                                    </small>
                                  )
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                    </ListGroup>
                    <h4 className="text-info mt-4">Comments</h4>
                      {comments ? (
                        comments.map((comment) => {
                          const madeOn = new Date(
                            comment.Made_On.replace(/-/g, "/")
                          );
                          const stringDate = madeOn.toString().substring(0, 15);
                          return (
                            <div className="p-3 mb-1">
                              <h6>
                                {comment.Made_By} | {stringDate}
                                <br />
                                <small className="text-muted">
                                  {comment.role}
                                </small>
                              </h6>
                              <h6 className="text-muted">{comment.Comment}</h6>
                              <hr />
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                  </Container>
                </Tab.Pane>

              </Tab.Content>
            </Col>

            {detailsToggle ? (
                <>
                  {application ? (
                    <>
                      <Col
                        md={3}
                        style={{
                          backgroundColor: "rgb(243, 244, 249)",
                          borderLeft: "2px solid #d0d5db",
                        }}
                      >
                        <h4 className="mt-3 mb-2">Details</h4>
                        <h6 className="text-muted">Date Applied</h6>
                        <h6>{application.Application_Date}</h6>
                        <br />
                        <h6 className="text-muted">Current Stage</h6>
                        <h6>{application.Status}</h6>
                        <br />
                        <h6 className="text-muted">Current Department</h6>
                        <h6 className="mb-2">{application.Stage}</h6>

                        <h4>Event Logs</h4>
                        {logs ? (
                          <div style={{ height: "700px", overflowY: "auto" }}>
                            {logs.map((log, index) => (
                              <div key={index}>
                                <h6>{log.Action}</h6>
                                <small className="text-muted">
                                  Made By: {log.Made_By}
                                </small>
                                <br />
                                <small className="text-muted">
                                  Made On: {log.Made_On}
                                </small>
                                <hr />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </Col>
                    </>
                  ) : (
                    "loading . . "
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
            <hr />
            {/* Comments section */}
            <br />
        </Tab.Container>
      </Container>
    </div>
  );
}

export default ViewAppRecords;
