import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Form,
  ListGroup,
  Tabs,
  Modal,
  Tab,
  Badge,
  InputGroup,
  Container,
  Button,
  ButtonGroup,
  Nav,
  Offcanvas,
  Card,
  Spinner,
} from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import {
  listApplications,
  listApplicationsRecords,
  detailApplication,
  commentsApplication,
  addCommentAction,
  logsApplication,
  updateApplication,
} from "../../actions/applicationActions";
import { retrieveFileAction } from "../../actions/fileActions";

import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { GridOptions } from "ag-grid-community";

import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import ModalImage from "../ModalImage";

import TimeAgo from "javascript-time-ago";
import StringCrypto from "string-crypto";

// English.
import en from "javascript-time-ago/locale/en.json";

import "./ApplicationForm.css";

import { uploadFileAction } from "../../actions/fileActions";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import ViewAppRecords from "./ViewAppRecords";
const MySwal = withReactContent(Swal);
let p = {};

TimeAgo.addDefaultLocale(en);
// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

function RecordsForm() {

  const Toast = MySwal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });

  const { encryptString } = new StringCrypto();

  const { height, width } = useWindowDimensions();
  const [commentShow, setCommentShow] = useState(false);
  const [show, setShow] = useState(false);

  const handleCommentClose = () => setCommentShow(false);
  const handleCommentShow = () => setCommentShow(true);

  let obj = JSON.parse(localStorage.getItem("userInfo"));
  let roleId = obj.message.original.roleId;

  let total_rebate = 0;
  const[totalRebate, setTotalRebate] = useState(0);

  const [new_eq_index, setNewEqIndex] = useState(0);
  const [old_eq_index, setOldEqIndex] = useState(0);
  const [count_equipment, setCountEquipment] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [showBatchModal, setBatchShowModal] = useState(false);
  const [showNewEquipmentInfo, setShowNewEquipmentInfo] = useState(false);
  const [showOldEquipmentInfo, setShowOldEquipmentInfo] = useState(false);
  const [equipmentInfo, setEquipmentInfo] = useState([]);
  const [applicationId, setApplicationId] = useState(0);
  const [status, setStatus] = useState("");
  const [stage, setStage] = useState("");
  const [reason, setReason] = useState("");
  const [batch, setBatch] = useState("");
  const [comment, setComment] = useState("");
  const [swalInfo, setSwalInfo] = useState("");
  const [updateState, setUpdateState] = useState(0);

  const [applicationClicked, setApplicationClicked] = useState(false);
  const [newEquipmentClicked, setNewEquipmentClicked] = useState(false);
  const [oldEquipmentClicked, setOldEquipmentClicked] = useState(false);
  const [submitDocsClicked, setSubmitDocsClicked] = useState(false);

  const [detailsToggle, setDetailsToggle] = useState(false);

  const handleModalClose = () => setShowModal(false);
  const handleBatchModalClose = () => setBatchShowModal(false);

  const [countryFix, setCountryFix] = useState("");
  const [mailingAddressFix, setMailingAddressFix] = useState("");

  const dispatch = useDispatch();

  const applicationListRecord = useSelector(
    (state) => state.applicationListRecord
  );
  const { applications } = applicationListRecord;

  const applicationDetail = useSelector((state) => state.applicationDetail);
  const { loading, error, application } = applicationDetail;

  const applicationComments = useSelector((state) => state.applicationComments);
  const { comments } = applicationComments;

  const applicationLogs = useSelector((state) => state.applicationLogs);
  const { logs } = applicationLogs;

  const applicationUpdate = useSelector((state) => state.applicationUpdate);
  const {
    error: updateError,
    loading: updateLoading,
    success: successUpdate,
  } = applicationUpdate;

  const addComment = useSelector((state) => state.addComment);
  const {
    error: commentError,
    loading: commentLoading,
    success: commentSucess,
  } = addComment;

  const retrieveFile = useSelector((state) => state.retrieveFile);
  const {
    error: retrieveError,
    loading: retrieveLoading,
    success: retrieveSuccess,
  } = retrieveFile;

  const batchCurrent = useSelector((state) => state.batchCurrent);
  const { batch_current } = batchCurrent;

  const batchAdd = useSelector((state) => state.batchAdd);
  const { success: addBatchSuccess } = batchAdd;

  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    description: "",
    image_sample: "",
  });

  const [invoice, setInvoice] = useState(null);
  const [irs_form, setIrsForm] = useState(null);
  const [disposal_slip, setDisposalSlip] = useState(null);
  const [letter_authorization, setLetterAuthorization] = useState(null);
  const [installer_certification, setInstallerCertification] = useState(null);
  const [other_doc1, setOtherDoc1] = useState(null);
  const [other_doc2, setOtherDoc2] = useState(null);
  const [other_doc3, setOtherDoc3] = useState(null);

  const [invoiceD, setInvoiceD] = useState("");
  const [irs_formD, setIrsFormD] = useState("");
  const [disposal_slipD, setDisposalSlipD] = useState("");
  const [letter_authorizationD, setLetterAuthorizationD] = useState();
  const [installer_certificationD, setInstallerCertificationD] = useState();
  const [other_doc1D, setOtherDoc1D] = useState("");
  const [other_doc2D, setOtherDoc2D] = useState("");
  const [other_doc3D, setOtherDoc3D] = useState("");
  const uploadFile = useSelector((state) => state.uploadFile);
  const { loading: uploadLoading, error: uploadError, fileCode } = uploadFile;
  // Grid State . . .
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [reload, setReload] = useState(0);

  // Pagination
  const [currentPage, setPage] = useState(1);
  const handlePage = (newPage) => {
    if(newPage > 0){
      setPage(newPage);
    }    
  };

  // Grid Functions . . .
  const onGridReady = (params) => {
    console.log(params)
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = (data) => {
      var differentHeights = [80, 80, 80, 80];
      data.forEach(function (dataItem, index) {
        dataItem.rowHeight = differentHeights[index % 4];
      });
      setRowData(data);
    };

    updateData(applications);
  };

  const getRowHeight = (params) => {
    return params.data.rowHeight;
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi.getSelectedRows();
    setApplicationId(selectedRows[0].Application_Id);
    dispatch(detailApplication(selectedRows[0].Application_Id));
    dispatch(commentsApplication(selectedRows[0].Application_Id));
    dispatch(logsApplication(selectedRows[0].Application_Id));
    setShow(true);
  };

  const printState = () => {
    var filterState = gridApi.getFilterModel();
    console.log("filterState: ", filterState);
  };

  const saveState = () => {
    window.filterState = gridApi.getFilterModel();
    console.log("filter state saved");
  };

  const restoreState = () => {
    gridApi.setFilterModel(window.filterState);
    console.log("filter state restored");
  };

  const resetState = () => {
    gridApi.setFilterModel(null);
    console.log("column state reset");
  };

  //for filtering . . .
  const filterParams = {
    filterOptions: [
      "empty",
      {
        displayKey: "evenNumbers",
        displayName: "Even Numbers",
        test: function (filterValue, cellValue) {
          return cellValue != null && cellValue % 2 === 0;
        },
        hideFilterInput: true,
      },
      {
        displayKey: "oddNumbers",
        displayName: "Odd Numbers",
        test: function (filterValue, cellValue) {
          return cellValue != null && cellValue % 2 !== 0;
        },
        hideFilterInput: true,
      },
      {
        displayKey: "blanks",
        displayName: "Blanks",
        test: function (filterValue, cellValue) {
          return cellValue == null;
        },
        hideFilterInput: true,
      },
    ],
    suppressAndOrCondition: true,
  };
  const containsFilterParams = {
    filterOptions: [
      "contains",
      {
        test: function (filterValue, cellValue) {
          return cellValue != null && cellValue.indexOf("a") === 0;
        },
        hideFilterInput: true,
      },
    ],
  };
  const equalsFilterParams = {
    filterOptions: [
      "equals",
      {
        displayKey: "equalsWithNulls",
        displayName: "Equals (with Nulls)",
        test: function (filterValue, cellValue) {
          if (cellValue == null) return true;
          const parts = cellValue.split("/");
          const cellDate = new Date(
            Number(parts[2]),
            Number(parts[1] - 1),
            Number(parts[0])
          );
          return cellDate.getTime() === filterValue.getTime();
        },
      },
    ],
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      const dateAsString = cellValue;
      if (dateAsString == null) return -1;
      const dateParts = dateAsString.split("/");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  const notEqualsFilterParams = {
    filterOptions: [
      "notEqual",
      {
        displayKey: "notEqualNoNulls",
        displayName: "Not Equals without Nulls",
        test: function (filterValue, cellValue) {
          if (cellValue == null) return false;
          return cellValue !== filterValue.toLowerCase();
        },
      },
    ],
  };

  const handleOnChange = (e, doc_type) => {
    if (doc_type === "irs_form") {
      setIrsForm(e.target.files[0]);
    } else if (doc_type === "other_doc1") {
      setOtherDoc1(e.target.files[0]);
    } else if (doc_type === "other_doc2") {
      setOtherDoc2(e.target.files[0]);
    } else if (doc_type === "letter_authorization") {
      setLetterAuthorization(e.target.files[0]);
    } else if (doc_type === "invoice") {
      setInvoice(e.target.files[0]);
    } else if (doc_type === "installer_certification") {
      setInstallerCertification(e.target.files[0]);
    } else if (doc_type === "disposal_receipt") {
      setDisposalSlip(e.target.files[0]);
    }
    dispatch(uploadFileAction(e.target.files[0], doc_type, 0, false));
    return;
  };
  // }, [application, successUpdate, addBatchSuccess,commentSucess])

  const selectHandler = () => {};

  const changeStatusHandler = (status) => {
    setStatus(status);
    setShowModal(true);

    console.log(status);
  };

  const updateStatus = (status, stage) => {
    console.log(status, " - ", stage);

    setStage(stage);

    if (status === 3) {
      Swal.fire({
        title: "Are you sure you want to reject application?",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        // denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateApplication(applicationId, status, stage, reason));
          setShow(false);
          setShowModal(false);
          Swal.fire("Success", "Application has been rejected!", "success");
        }
      });
    } else {
      if (swalInfo !== "") {
        Swal.fire({
          title: `Are you sure you want to ${swalInfo}?`,
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Save",
          // denyButtonText: `Cancel`,
        }).then((result) => {
          if (result.isConfirmed) {
            setStatus(status);
            setStage(stage);
            dispatch(
              updateApplication(applicationId, status, stage, reason, batch)
            );
            setShowModal(false);
            Swal.fire("Success", "Application has been processed!", "success");
          }
        });
      }
      setUpdateState(updateState + 1);
    }
  };

  useEffect(() => {
     Toast.fire({
       icon: "info",
       title: "Loading Data",
       text: "Please wait while the table is loading the data.",
     });
      dispatch(listApplicationsRecords(currentPage.toString()));
        dispatch(detailApplication(applicationId));
        dispatch(logsApplication(applicationId));

        dispatch(commentsApplication(applicationId));
  }, [reload, currentPage]);

  const resetHandler = () => {
    setShow(false);
  };

  const selectEquipment = (id, equipmentType) => {
    if (equipmentType === "new_equipment") {
      if (application.New_equipment[id]) {
        setEquipmentInfo(application.New_equipment[id]);
        setShowNewEquipmentInfo(true);
      }
    } else {
      if (application.Old_equipment[id]) {
        setEquipmentInfo(application.Old_equipment[id]);
        setShowOldEquipmentInfo(true);
      }
    }
  };

  const showNewEquipmentInformation = (index) => {
    setNewEqIndex(index);
    console.log(application);
  };

  const addCommentHandler = () => {
    dispatch(addCommentAction(applicationId, comment));

    const Toast = MySwal.mixin({
      toast: true,
      position: "bottom-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3500,
      timerProgressBars: true,
    });

    Toast.fire({
      icon: "success",
      title: "Comment Sent",
      text: "",
    });

    setComment("");
  };

  const changeCommentHandler = (text) => {
    setComment(text);
  };

  // testing lng ...
  const ButtonClick = (selected) => {
    const onButtonClick = () => {
        Toast.fire({
          icon: "info",
          title: "Loading Data",
          text: "Please wait while the table is loading the data.",
        });
      console.log("selected application: ", selected.data);
      dispatch(detailApplication(selected.data.Application_Id));
      setApplicationId(selected.data.Application_Id);

      dispatch(commentsApplication(selected.data.Application_Id));
      dispatch(logsApplication(selected.data.Application_Id));
      setShow(true);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          style={{ width: 70 }}
          variant="success"
          size="sm"
          onClick={() => onButtonClick()}
        >
          View
        </Button>
      </div>
    );
  };

  const reader = new FileReader();

  const handleRetrieveFile = (code, filename) => {
    console.log(code);
    dispatch(retrieveFileAction(code, filename));
  };

  const handleDetailsToggle = () => {
    if (detailsToggle === false) {
      setDetailsToggle(true);
    } else {
      setDetailsToggle(false);
    }
  };

  const reloadTableHandler = () => {
    setReload(reload+1)
  }

  const handleReloadApplication = () => {
    setReload(reload + 1);
  }

  total_rebate = 0;

  useEffect(() => {
    if(application?.Info_Mailing_address != undefined){
      const words = application.Info_Mailing_address.split("^")
      setMailingAddressFix(words[0])
      setCountryFix(words[1] ? words[1] : "N/A")
    }
    if(application?.New_equipment != undefined){
      application.New_equipment.map((eq) => {
        total_rebate = total_rebate + eq.newEquip_rebate
        console.log(eq.newEquip_rebate)
      });
      setTotalRebate(total_rebate)
      console.log("application: ", application);
      console.log("totalRebate: ", total_rebate);
    }
    
  }, [application]);

  return (
    <div>
      {!show ? (
        <Container>
        <div
          className="ag-theme-alpine"
          style={{ height: 400, width: 100 + "%" }}
        >
          {/* <Button onClick={() => printState()} className="me-2" variant={"info"}>Print State</Button> */}
          {/* <Button onClick={() => saveState()} className="me-2" size='sm' variant={"success"}>Save State</Button>
                      <Button onClick={() => restoreState()} className="me-2" size='sm' variant={"secondary"}>Restore State</Button> */}
          <Row>
            <Col md="12" style={{ padding: 0 }}>
              <Button
                onClick={() => resetState()}
                className="mb-2 float-end"
                size="sm"
                variant={"success"}
              >
                Reset Filter
              </Button>
              <Button
                onClick={() => reloadTableHandler()}
                className="mb-2 me-2 float-end"
                size="sm"
                variant={"success"}
              >
                Reload Table
              </Button>
            </Col>
          </Row>
          <AgGridReact
            frameworkComponents={{
              buttonAction: ButtonClick,
            }}
            defaultColDef={{
              flex: 1,
              minWidth: 150,
              sortable: true,
              filter: true,
              resizable: true,
            }}
            localeTextFunc={function (key, defaultValue) {
              if (key === "notEqualNoNulls") {
                return "* Not Equals (No Nulls) *";
              }
              return defaultValue;
            }}
            rowSelection={"single"}
            //   onCellDoubleClicked={onSelectionChanged}
            //   getRowHeight={getRowHeight}
            onGridReady={onGridReady}
            rowData={applications?.length >= 1 ? applications : []}
            columnTypes={{
              medalColumn: {
                flex: 1,
                maxWidth: 120,
                filter: false,
              },
            }}
            rowHeight={40}
          >
            <AgGridColumn headerName="Control Number" field="Control_Number"/>
            <AgGridColumn headerName="Batch Code" field="Batch_code" />
            <AgGridColumn headerName="Name" field="customer_name" />
            <AgGridColumn headerName="Application Date" field="Application_Date"/>
            <AgGridColumn field="Stage" />
            <AgGridColumn field="Status" />
            <AgGridColumn headerName="System Type" field="System_Type" />
            <AgGridColumn field="Action" type="medalColumn" columnGroupShow="closed" cellRenderer="buttonAction"/>
          </AgGridReact>
        </div>
        <div className="mt-5 d-flex flex-row justify-content-center align-items-center">
            <Button
              className="mx-2"
              onClick={() => handlePage(currentPage - 1)}
              size="sm"
              variant={"success"}
            >
              <FaChevronLeft className="" />
            </Button>
            <Button
              className={`mx-1 fw-bold text-success ${currentPage - 2 <= 0 ? "d-none" : ""}`}
              onClick={() => handlePage(currentPage - 2)}
              size="sm"
              variant={"light"}
            >
              {currentPage - 2}
            </Button>
            <Button
              className={`mx-1 fw-bold text-success ${currentPage - 1 <= 0 ? "d-none" : ""}`}
              onClick={() => handlePage(currentPage - 1)}
              size="sm"
              variant={"light"}
            >
              {currentPage - 1}
            </Button>
            <Button
              className="mx-1 fw-bold"
              onClick={() => { }}
              size="sm"
              variant={"success"}
            >
              {currentPage}
            </Button>
            <Button
              className="mx-1 fw-bold text-success"
              onClick={() => handlePage(currentPage + 1)}
              size="sm"
              variant={"light"}
            >
              {currentPage + 1}
            </Button>
            <Button
              className="mx-1 fw-bold text-success"
              onClick={() => handlePage(currentPage + 2)}
              size="sm"
              variant={"light"}
            >
              {currentPage + 2}
            </Button>
            <Button
              className="mx-2"
              onClick={() => handlePage(currentPage + 1)}
              size="sm"
              variant={"success"}
            >
              <FaChevronRight className="" />
            </Button>
        </div>
      </Container>
      ) : (
        <ViewAppRecords
        show={show}
        setShow={setShow}
        reload={reload}
        setReload={setReload}
        applicationId={applicationId}
        setApplicationId={setApplicationId}
        isApplication = {false}
        />
      )}
    </div>
  );
}

export default RecordsForm;
