export const CUSTOMER_REGISTER_REQUEST = "CUSTOMER_REGISTER_REQUEST";
export const CUSTOMER_REGISTER_SUCCESS = "CUSTOMER_REGISTER_SUCCESS";
export const CUSTOMER_REGISTER_FAIL = "CUSTOMER_REGISTER_FAIL";

export const CUSTOMER_GENERATE_CONTROLNO_REQUEST =
  "CUSTOMER_GENERATE_CONTROLNO_REQUEST";
export const CUSTOMER_GENERATE_CONTROLNO_SUCCESS =
  "CUSTOMER_GENERATE_CONTROLNO_SUCCESS";
export const CUSTOMER_GENERATE_CONTROLNO_FAIL =
  "CUSTOMER_GENERATE_CONTROLNO_FAIL";

export const CUSTOMER_VERIFY_REQUEST = "CUSTOMER_VERIFY_REQUEST";
export const CUSTOMER_VERIFY_SUCCESS = "CUSTOMER_VERIFY_SUCCESS";
export const CUSTOMER_VERIFY_FAIL = "CUSTOMER_VERIFY_FAIL";

export const CUSTOMER_DETAIL_REQUEST = "CUSTOMER_DETAIL_REQUEST";
export const CUSTOMER_DETAIL_SUCCESS = "CUSTOMER_DETAIL_SUCCESS";
export const CUSTOMER_DETAIL_FAIL = "CUSTOMER_DETAIL_FAIL";
export const CUSTOMER_DETAIL_RESET = "CUSTOMER_DETAIL_RESET";

export const CUSTOMER_EQUIP_MANUFACTURER_REQUEST =
  "CUSTOMER_EQUIP_MANUFACTURER_REQUEST";
export const CUSTOMER_EQUIP_MANUFACTURER_SUCCESS =
  "CUSTOMER_EQUIP_MANUFACTURER_SUCCESS";
export const CUSTOMER_EQUIP_MANUFACTURER_FAIL =
  "CUSTOMER_EQUIP_MANUFACTURER_FAIL";
export const CUSTOMER_EQUIP_MANUFACTURER_RESET =
  "CUSTOMER_EQUIP_MANUFACTURER_RESET";

  export const CUSTOMER_EQUIP_MODEL_REQUEST = "CUSTOMER_EQUIP_MODEL_REQUEST";
  export const CUSTOMER_EQUIP_MODEL_SUCCESS = "CUSTOMER_EQUIP_MODEL_SUCCESS";
  export const CUSTOMER_EQUIP_MODEL_FAIL = "CUSTOMER_EQUIP_MODEL_FAIL";
  export const CUSTOMER_EQUIP_MODEL_RESET = "CUSTOMER_EQUIP_MODEL_RESET";
  
export const CUSTOMER_SYSTEM_TYPE_REQUEST = "CUSTOMER_SYSTEM_TYPE_REQUEST";
export const CUSTOMER_SYSTEM_TYPE_SUCCESS = "CUSTOMER_SYSTEM_TYPE_SUCCESS";
export const CUSTOMER_SYSTEM_TYPE_FAIL = "CUSTOMER_SYSTEM_TYPE_FAIL";

export const CUSTOMER_EQUIPMENT_DETAIL_REQUEST =
  "CUSTOMER_EQUIPMENT_DETAIL_REQUEST";
export const CUSTOMER_EQUIPMENT_DETAIL_SUCCESS =
  "CUSTOMER_EQUIPMENT_DETAIL_SUCCESS";
export const CUSTOMER_EQUIPMENT_DETAIL_FAIL = "CUSTOMER_EQUIPMENT_DETAIL_FAIL";
export const CUSTOMER_EQUIPMENT_DETAIL_RESET =
  "CUSTOMER_EQUIPMENT_DETAIL_RESET";
