import React, {useEffect, useState} from "react";
import { Row, Col, Image } from "react-bootstrap";

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

import { Link } from "react-router-dom";

import "./HomeScreen.css";

function iOS() {

  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function  PageNotFound() {
  const [isIOS, setIsIOS] = useState(false);
  
  useEffect(()=>{
    if (iOS()) {
      setIsIOS(true);
    }
  }, []);

  return (
    <Row md={12} xs={12} sm={3} id="homeScreen" style={{justifyContent:"center"}}>
      <Col
        className="text-center position-relative main-content"
        md={11}
        sm={11}
      >
        <Row className="justify-content-center">
          <Row>
            <Col sm={4} md={4}></Col>
            <Col sm={4} md={4}>
              <Image src="/icon.png" className="mb-3" fluid />
            </Col>
            <Col sm={4} md={4}></Col>
          </Row>
          <Row>
              <h2 className="mb-4 text-white" id="applyHead">
                THE PAGE YOU ARE ACCESSING IS NOT AVAILABE
              </h2>
          </Row>
          
          <br />
          
          <p className="pd-6">
            <a
              href={process.env.REACT_APP_FRONT}
              className="px-5 mb-4 text-white"
            >
              <u>go back to homescreen</u>
            </a>
          </p>
        </Row>
        <Row>
          <br />
          <small className="text-secondary position-absolute footer-text">
            Energy Sense Rebate Program <br />
            Copyright &copy; 2022 GPA Powered By Xtendly
          </small>
        </Row>
      </Col>
    </Row>
  );
}

export default PageNotFound;
